// StakeholderIdentification.tsx

import React, { useState, useEffect, useRef, useContext } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/StakeholderIdentification.css';
import Loading from './loading/Loading';
import { Helmet } from 'react-helmet';
import { Document, StakeholderData } from '../types';
import {
  StakeholderIdentificationContext,
  useProjectContext,
} from '../ContextStore';

interface StakeholderIdentificationProps {
  isDarkMode: boolean;
}

const StakeholderIdentification: React.FC<StakeholderIdentificationProps> = ({
  isDarkMode,
}) => {
  const [query, setQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [errorKey, setErrorKey] = useState<number>(0);
  const [retryAttempt, setRetryAttempt] = useState<boolean>(false);

  // Use the context for response state
  const stakeholderContext = useContext(StakeholderIdentificationContext);
  if (!stakeholderContext) {
    throw new Error('StakeholderIdentificationContext not found');
  }
  const { stakeholderResponse, setStakeholderResponse } = stakeholderContext;

  const { selectedProjectId } = useProjectContext();
  const [savedStakeholders, setSavedStakeholders] = useState<Set<string>>(new Set());

  // Library dropdown state
  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null);
  const [selectedDocumentTitle, setSelectedDocumentTitle] =
    useState<string>('Library');
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const libraryDropdownMenuRef = useRef<HTMLDivElement>(null);
  const libraryDropdownButtonRef = useRef<HTMLButtonElement>(null);

  const stakeURL = process.env.REACT_APP_STAKEHOLDER_IDENTIFICATION_API_URL;
  const documentsURL = process.env.REACT_APP_DOCUMENTS_API_URL;
  if (!stakeURL || !documentsURL) {
    throw new Error('Missing required environment variables');
  }

  // Function to save a stakeholder to the project
  const handleSaveStakeholder = async (stakeholder: StakeholderData) => {
    try {
      const stakeholderData = {
        organization: stakeholder.organization,
        name: stakeholder.name,
        title: stakeholder.title,
        email: stakeholder.email,
        source: stakeholder.source
      };

      await axiosRequest(
        `${process.env.REACT_APP_PROJECTS_API_URL}${selectedProjectId}/stakeholders/save/`,
        'post',
        stakeholderData
      );

      // Update local state to show the stakeholder is saved
      setSavedStakeholders(prev => new Set(prev).add(stakeholder.organization + stakeholder.name));
    } catch (error) {
      console.error('Error saving stakeholder:', error);
      setError('Failed to save stakeholder. Please try again.');
    }
  };

  // ------------------- Fetch documents for the library dropdown -------------------
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axiosRequest(documentsURL, 'get');
        const sortedDocuments = response.data.sort(
          (a: Document, b: Document) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        setDocuments(sortedDocuments);
      } catch (err: any) {
        setError('Error fetching documents');
      }
    };
    fetchDocuments();
  }, [documentsURL]);

  // Sync local query with stakeholderResponse.prompt from context
  useEffect(() => {
    if (stakeholderResponse && (stakeholderResponse as any).prompt) {
      setQuery((stakeholderResponse as any).prompt);
    }
  }, [stakeholderResponse]);

  // ------------------- Form Submit -------------------
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setRetryAttempt(false);

    // Clear previous response but keep a new empty array with a prompt property
    // so that ProjectDashboard can read it from stakeholderResponse.
    const newArr: any[] = [];
    (newArr as any).prompt = query;
    setStakeholderResponse(newArr);

    // Validate user input
    if (!query.trim() && !selectedDocumentId) {
      setError('Please input a query or select a document.');
      setLoading(false);
      return;
    }

    // Ensure selectedProjectId is available
    if (!selectedProjectId) {
      setError('No default project selected. Please select a project.');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('query', query);
    if (selectedDocumentId) {
      formData.append('documentId', selectedDocumentId);
    }
    formData.append('project_id', selectedProjectId);

    try {
      await initiateStreaming(formData);
    } catch (error: any) {
      setLoading(false);
      setErrorKey((prevKey) => prevKey + 1);

      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error); // Display backend's specific error message
      } else if (error.response && error.response.status === 401) {
        setError('Unauthorized. Please log in with a valid email and password.');
      } else if (error instanceof Error) {
        console.error('Error initiating request:', error);
        setError(`An error occurred while initiating the request: ${error.message}`);
      } else {
        console.error('Unexpected error:', error);
        setError('An unexpected error occurred');
      }
    }
  };

  // ------------------- SSE Initiation -------------------
  const initiateStreaming = async (formData: FormData, retry: boolean = false) => {
    try {
      // Make the POST request to get temp_token (JWT) and possibly updated query
      const postResponse = await axiosRequest(stakeURL, 'post', formData);
      const { temp_token, query: updatedQuery } = postResponse.data;

      // Use the updated query from the response if provided
      const finalQuery = updatedQuery || query;

      // Also update the prompt in stakeholderResponse for the dashboard to read
      setStakeholderResponse((prev: any) => {
        const copy = Array.isArray(prev) ? [...prev] : [];
        (copy as any).prompt = finalQuery;
        return copy;
      });

      // Construct the EventSource URL with temp_token (JWT)
      const eventSourceUrl = `${stakeURL}?query=${encodeURIComponent(
        finalQuery
      )}&temp_token=${encodeURIComponent(temp_token)}`;

      const eventSource = new EventSource(eventSourceUrl);

      eventSource.onopen = () => {
        console.log('Connection to server opened.');
      };

      eventSource.onmessage = (event) => {
        const newMessage = JSON.parse(event.data);
        console.log('Received message:', newMessage);

                        // if (newMessage.summary) {
        //   handleSummaryResponse(newMessage.summary);
        //   eventSource.close();
        //   setLoading(false);
        // } else {
        //   handleInitialBatchResponse(newMessage as StakeholderData[]);
        // }


        if (newMessage.summary) {
          // If there's a summary, the stream ends
          eventSource.close();
          setLoading(false);
        } else {
          // Otherwise, we assume it's an array of stakeholder data
          handleInitialBatchResponse(newMessage as StakeholderData[]);
        }
      };

      eventSource.onerror = async (event) => {
        console.error('EventSource error:', event);
        eventSource.close();

        if (!retryAttempt && !retry) {
          setRetryAttempt(true);
          console.warn('Retrying EventSource connection...');
          await initiateStreaming(formData, true);
        } else {
          setLoading(false);
          setError('An error occurred while streaming data.');
          setRetryAttempt(false);
        }
      };
    } catch (error: any) {
      console.error('Error in initiateStreaming:', error);

      if (!retryAttempt && !retry) {
        setRetryAttempt(true);
        console.warn('Retrying request after error...');
        await initiateStreaming(formData, true);
      } else {
        if (error.response && error.response.data && error.response.data.error) {
          setError(error.response.data.error);
        } else if (error.response && error.response.status === 401) {
          setError('Unauthorized. Please log in with a valid email and password.');
        } else {
          setError('An error occurred while initiating the request.');
        }
      }
    }
  };

  // ------------------- SSE Data Handlers -------------------
  const handleInitialBatchResponse = (message: StakeholderData[]) => {
    // Ensure message is an array
    const newMessages = Array.isArray(message) ? message : [message];

    setStakeholderResponse((prevResponse: any) => {
      const copy = Array.isArray(prevResponse) ? [...prevResponse] : [];
      copy.push(...newMessages);
      // Keep existing prompt
      (copy as any).prompt = (prevResponse as any)?.prompt || '';
      return copy;
    });
  };

  // Example approach for a "summary" (if your SSE returns a summary)
  const handleSummaryResponse = (summary: string) => {
    const formattedSummary: StakeholderData[] = summary.split('\n\n').map((item) => {
      const lines = item.split('\n').map((line) => line.trim());
      const details: StakeholderData = { organization: '' };
      const orgNameMatch = lines[0].match(/^\d+\.\s\*\*(.*?)\*\*/);
      if (orgNameMatch) {
        details.organization = orgNameMatch[1].trim();
      }
      lines.slice(1).forEach((line) => {
        const [key, value] = line.split(':').map((part) => part.replace('**', '').trim());
        if (key && value) {
          const normalizedKey = key.replace(/[^a-zA-Z]/g, '').toLowerCase();
          details[normalizedKey as keyof StakeholderData] = value;
        }
      });
      return details;
    });
    setStakeholderResponse((prev: any) => {
      // Keep the old prompt in the newly created array
      const copy = Array.isArray(prev) ? [...prev, ...formattedSummary] : [];
      (copy as any).prompt = (prev as any)?.prompt || '';
      return copy;
    });
    setLoading(false);
  };

  // ------------------- Format SSE Data for the UI -------------------
  const formatResponse = (data: StakeholderData, index: number) => {
    return (
      <div
        id={`result-content-${index}`}
        className="stakeholder-identification-result-content"
      >
        <div>
          <strong>{data.organization}</strong>
        </div>
        <div>- <strong>Name:</strong> {data.name || 'No data'}</div>
        <div>- <strong>Title:</strong> {data.title || 'No data'}</div>
        <div>- <strong>Email:</strong> {data.email || 'No data'}</div>
        <div>- <strong>Source:</strong> {data.source || 'No data'}</div>
      </div>
    );
  };

  // If we detect a prompt in context, also update local input field
  useEffect(() => {
    if (Array.isArray(stakeholderResponse) && (stakeholderResponse as any).prompt) {
      setQuery((stakeholderResponse as any).prompt);
    }
  }, [stakeholderResponse]);

  // ------------------- Focus the error message when it appears -------------------
  useEffect(() => {
    if (error && errorRef.current) {
      errorRef.current.focus();
    }
  }, [error]);

  // ------------------- Close the dropdown menu when clicking outside -------------------
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownOpen &&
        libraryDropdownMenuRef.current &&
        !libraryDropdownMenuRef.current.contains(event.target as Node) &&
        !libraryDropdownButtonRef.current?.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  // ------------------- Keyboard navigation for the dropdown menu -------------------
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (dropdownOpen && libraryDropdownMenuRef.current) {
        const items = libraryDropdownMenuRef.current.querySelectorAll(
          'div[role="menuitem"]'
        );
        const activeIndex = Array.from(items).findIndex(
          (item) => item === document.activeElement
        );

        switch (event.key) {
          case 'ArrowDown': {
            event.preventDefault();
            const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
            (items[nextIndex] as HTMLElement).focus();
            break;
          }
          case 'ArrowUp': {
            event.preventDefault();
            const prevIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
            (items[prevIndex] as HTMLElement).focus();
            break;
          }
          case 'Enter':
            if (activeIndex !== -1) {
              event.preventDefault();
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Tab':
            if (event.shiftKey) {
              // Shift + Tab
              if (activeIndex === 0) {
                event.preventDefault();
                setDropdownOpen(false);
                libraryDropdownButtonRef.current?.focus();
              }
            } else {
              // Tab
              if (activeIndex === items.length - 1) {
                event.preventDefault();
                setDropdownOpen(false);
                submitButtonRef.current?.focus();
              }
            }
            break;
          case 'Escape':
            setDropdownOpen(false);
            libraryDropdownButtonRef.current?.focus();
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [dropdownOpen]);

  // ------------------- Focus first item when the library dropdown opens -------------------
  useEffect(() => {
    if (dropdownOpen && libraryDropdownMenuRef.current) {
      const firstItem = libraryDropdownMenuRef.current.querySelector(
        'div[role="menuitem"]'
      );
      (firstItem as HTMLElement)?.focus();
    }
  }, [dropdownOpen]);

  // ------------------- Library dropdown toggle & selection -------------------
  const handleLibraryDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLibrarySelection = (
    event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    documentId: string | null,
    documentTitle: string
  ) => {
    event.preventDefault();
    if ('stopPropagation' in event) event.stopPropagation();

    setSelectedDocumentId(documentId);
    setSelectedDocumentTitle(documentTitle);
    setDropdownOpen(false);

    // Delay focusing on the submit button to prevent Enter from triggering it
    setTimeout(() => {
      submitButtonRef.current?.focus();
    }, 0);
  };

  return (
    <div className={`stakeholder-identification-page ${isDarkMode ? 'dark' : 'light'}`}>
      <Helmet>
        <title>Stakeholder Identification | Innoscale AI</title>
      </Helmet>

      <div className="stakeholder-identification-header">
        <div className="stakeholder-identification-title-row">
          <div className="stakeholder-identification-title-section">
            <h1 className="stakeholder-identification-title">
              <div className="stakeholder-identification-prompt-container">
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="GPS Navigation"
                    className={`stakeholder-identification-query-input ${isDarkMode ? 'dark' : 'light'}`}
                    disabled={loading}
                  />
                </form>
                <button
                  ref={submitButtonRef}
                  onClick={(e) => handleSubmit(e as unknown as React.FormEvent)}
                  className={`stakeholder-identification-submit-button ${isDarkMode ? 'dark' : ''} ${
                    loading ? 'loading' : ''
                  }`}
                  disabled={loading}
                  aria-label="Submit"
                >
                  ➤
                </button>
              </div>
            </h1>
          </div>
        </div>

        <div className="stakeholder-identification-buttons-row">
          <div className="stakeholder-identification-controls">
            <div className="stakeholder-identification-button-with-tooltip">
              <button
                ref={libraryDropdownButtonRef}
                onClick={handleLibraryDropdownClick}
                className={`stakeholder-identification-library-dropdown-button ${isDarkMode ? 'dark' : ''}`}
                aria-expanded={dropdownOpen}
                aria-controls="stakeholder-identification-library-dropdown-menu"
                type="button"
              >
                📂 {selectedDocumentTitle}
              </button>
              <span className="stakeholder-identification-tooltip-text">
                Select a document from your library
              </span>
              <div
                ref={libraryDropdownMenuRef}
                id="stakeholder-identification-library-dropdown-menu"
                className={`stakeholder-identification-library-dropdown-menu ${isDarkMode ? 'dark' : ''} ${
                  dropdownOpen ? 'show' : ''
                }`}
                role="menu"
                aria-hidden={!dropdownOpen}
              >
                <div
                  className={`stakeholder-identification-dropdown-item ${isDarkMode ? 'dark' : ''}`}
                  onClick={(e) => handleLibrarySelection(e, null, 'Library')}
                  role="menuitem"
                  tabIndex={0}
                >
                  -- No Document --
                </div>
                {documents.map((doc) => (
                  <div
                    key={doc.id}
                    className={`stakeholder-identification-dropdown-item ${isDarkMode ? 'dark' : ''}`}
                    onClick={(e) => handleLibrarySelection(e, doc.id, doc.documentTitle)}
                    role="menuitem"
                    tabIndex={0}
                  >
                    {doc.documentTitle}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="stakeholder-identification-underline" />

      <div className="stakeholder-identification-main-content">
        {loading && <Loading />}
        
        {error && (
          <div
            key={errorKey}
            ref={errorRef}
            className={`stakeholder-identification-error ${isDarkMode ? 'dark' : ''}`}
            role="alert"
          >
            {error}
          </div>
        )}

        {stakeholderResponse && !loading && (
          <div className="stakeholder-identification-results">
            {stakeholderResponse.map((item: StakeholderData, index: number) => (
              <div
                key={index}
                className={`stakeholder-identification-result-card ${
                  isDarkMode ? 'dark' : 'light'
                }`}
                role="region"
                aria-labelledby={`result-${index}`}
                tabIndex={0}
                aria-describedby={`result-content-${index}`}
              >
                <div className="visually-hidden" id={`result-${index}`}>
                  Result {index + 1}
                </div>
                <div className="stakeholder-identification-result-content">
                  <div>
                    <strong>{item.organization}</strong>
                  </div>
                  <div>- <strong>Name:</strong> {item.name || 'No data'}</div>
                  <div>- <strong>Title:</strong> {item.title || 'No data'}</div>
                  <div>- <strong>Email:</strong> {item.email || 'No data'}</div>
                  <div>- <strong>Source:</strong> {item.source || 'No data'}</div>
                  <div className="stakeholder-identification-save-button-container">
                    <button
                      onClick={() => handleSaveStakeholder(item)}
                      className={`stakeholder-identification-save-button ${isDarkMode ? 'dark' : 'light'} ${
                        savedStakeholders.has(item.organization + item.name) ? 'saved' : ''
                      }`}
                      disabled={savedStakeholders.has(item.organization + item.name)}
                      aria-label={`Save stakeholder from ${item.organization}`}
                    >
                      {savedStakeholders.has(item.organization + item.name) ? 'Saved ✓' : 'Save'}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default StakeholderIdentification;
