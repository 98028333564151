import React from 'react';
import { Scatter } from 'react-chartjs-2';
import { Chart as ChartJS, ChartOptions, ChartData } from 'chart.js';

interface ProjectDashboardMarketResearchProps {
  companies: any[];
  isDarkMode: boolean;
  quadrantCuts?: {x_cut: number, y_cut: number};
  axisRanges?: {x_min: number, x_max: number, y_min: number, y_max: number};
}

const ProjectDashboardMarketResearch: React.FC<ProjectDashboardMarketResearchProps> = ({ 
  companies, 
  isDarkMode,
  quadrantCuts = {x_cut: 0.5, y_cut: 0.5},
  axisRanges = {x_min: 0, x_max: 1, y_min: 0, y_max: 1}
}) => {
  // Filter out companies without scores
  const scoredCompanies = companies.filter(
    (company) => 
      company.is_scored && 
      typeof company.ability_to_execute_score === 'number' && 
      typeof company.completeness_of_vision_score === 'number'
  );

  // Get the cut values from props or use defaults
  const x_cut = quadrantCuts.x_cut;
  const y_cut = quadrantCuts.y_cut;
  
  // Get the axis ranges from props or use defaults
  // Ensure we never have negative values on either axis
  const x_min = Math.max(0, axisRanges.x_min);
  const x_max = axisRanges.x_max;
  const y_min = Math.max(0, axisRanges.y_min);
  const y_max = axisRanges.y_max;

  // Function to classify companies into quadrants
  const classify_quadrant = (vision_score: number, execution_score: number): string => {
    if (vision_score >= x_cut && execution_score >= y_cut) {
      return 'Leader';
    } else if (vision_score < x_cut && execution_score >= y_cut) {
      return 'Challenger';
    } else if (vision_score >= x_cut && execution_score < y_cut) {
      return 'Visionary';
    } else {
      return 'Niche Player';
    }
  };

  // Prepare data for the chart
  const chartData: ChartData<'scatter'> = {
    datasets: scoredCompanies.map((company, index) => ({
      label: company.company,
      data: [
        {
          x: company.completeness_of_vision_score,
          y: company.ability_to_execute_score,
        },
      ],
      backgroundColor: getQuadrantColor(classify_quadrant(company.completeness_of_vision_score, company.ability_to_execute_score), 0.7),
      borderColor: getQuadrantColor(classify_quadrant(company.completeness_of_vision_score, company.ability_to_execute_score), 1),
      pointRadius: 8,
      pointHoverRadius: 10,
    })),
  };

  // Chart options
  const chartOptions: ChartOptions<'scatter'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        min: x_min,
        max: x_max,
        title: {
          display: true,
          text: 'Completeness of Vision',
          color: isDarkMode ? '#fff' : '#333',
        },
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          color: isDarkMode ? '#fff' : '#333',
        },
      },
      y: {
        min: y_min,
        max: y_max,
        title: {
          display: true,
          text: 'Ability to Execute',
          color: isDarkMode ? '#fff' : '#333',
        },
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        },
        ticks: {
          color: isDarkMode ? '#fff' : '#333',
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const company = scoredCompanies[context.datasetIndex || 0];
            return [
              `${company.company} (${classify_quadrant(company.completeness_of_vision_score, company.ability_to_execute_score)})`,
              `Vision: ${(company.completeness_of_vision_score * 100).toFixed(1)}%`,
              `Execution: ${(company.ability_to_execute_score * 100).toFixed(1)}%`,
              `Source: ${company.source || 'Unknown'}`,
            ];
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  // Add quadrant annotations
  const quadrantPlugin = {
    id: 'quadrantPlugin',
    afterDatasetsDraw: (chart: any) => {
      // Draw quadrant lines and labels AFTER datasets to ensure they're on top
      const { ctx, chartArea } = chart;
      if (!chartArea) return;

      const { left, top, right, bottom } = chartArea;
      
      // Calculate positions based on dynamic cut values and axis ranges
      const xRange = x_max - x_min;
      const yRange = y_max - y_min;
      
      // Calculate the position of the cut lines in pixel coordinates
      const xPos = left + ((x_cut - x_min) / xRange) * (right - left);
      const yPos = top + (1 - ((y_cut - y_min) / yRange)) * (bottom - top);

      // Draw quadrant dividers
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(xPos, top);
      ctx.lineTo(xPos, bottom);
      ctx.moveTo(left, yPos);
      ctx.lineTo(right, yPos);
      ctx.strokeStyle = isDarkMode ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.6)';
      ctx.lineWidth = 2;  // Make quadrant dividers thicker
      ctx.stroke();

      // Add quadrant labels with background for better visibility
      ctx.font = 'bold 12px Arial';  // Make font bold
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      
      // Function to draw text with background
      const drawLabelWithBackground = (text: string, x: number, y: number) => {
        // Measure text width
        const textMetrics = ctx.measureText(text);
        const textWidth = textMetrics.width;
        const textHeight = 16;  // Approximate height
        
        // Draw background
        ctx.fillStyle = isDarkMode ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)';
        ctx.fillRect(
          x - textWidth / 2 - 4,  // Add padding
          y - textHeight / 2 - 2,  // Add padding
          textWidth + 8,  // Add padding
          textHeight + 4   // Add padding
        );
        
        // Draw text
        ctx.fillStyle = isDarkMode ? '#fff' : '#333';
        ctx.fillText(text, x, y);
      };

      // Leaders (top right)
      drawLabelWithBackground('Leaders', xPos + (right - xPos) / 2, top + (yPos - top) / 2);
      
      // Challengers (top left)
      drawLabelWithBackground('Challengers', left + (xPos - left) / 2, top + (yPos - top) / 2);
      
      // Visionaries (bottom right)
      drawLabelWithBackground('Visionaries', xPos + (right - xPos) / 2, yPos + (bottom - yPos) / 2);
      
      // Niche Players (bottom left)
      drawLabelWithBackground('Niche Players', left + (xPos - left) / 2, yPos + (bottom - yPos) / 2);

      ctx.restore();
    },
    // Keep the original beforeDraw hook but only for calculations, not drawing
    beforeDraw: (chart: any) => {
      // This is needed for Chart.js to properly calculate layout
      // but we won't draw anything here
    }
  };

  return (
    <div style={{ height: '250px', width: '100%', position: 'relative' }}>
      {scoredCompanies.length > 0 ? (
        <Scatter 
          data={chartData} 
          options={chartOptions} 
          plugins={[quadrantPlugin]} 
        />
      ) : (
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100%',
          color: isDarkMode ? '#fff' : '#333'
        }}>
          No scored companies available
        </div>
      )}
    </div>
  );
};

// Function to get color based on quadrant
const getQuadrantColor = (quadrant: string, alpha: number): string => {
  switch (quadrant) {
    case 'Leader':
      return `rgba(46, 204, 113, ${alpha})`; // Green
    case 'Challenger':
      return `rgba(52, 152, 219, ${alpha})`; // Blue
    case 'Visionary':
      return `rgba(155, 89, 182, ${alpha})`; // Purple
    case 'Niche Player':
      return `rgba(241, 196, 15, ${alpha})`; // Yellow
    default:
      return `rgba(149, 165, 166, ${alpha})`; // Gray
  }
};

export default ProjectDashboardMarketResearch;
