// ContextStore.tsx

import React, { createContext, useContext } from 'react';
import { StakeholderData, CompanyData } from './types';

interface TechScoutingContextType {
  techResponse: any;
  setTechResponse: React.Dispatch<React.SetStateAction<any>>;
  selectedCompany: CompanyData | null;
  setSelectedCompany: React.Dispatch<React.SetStateAction<CompanyData | null>>;
  selectedCompanyOrigin: string | null;
  setSelectedCompanyOrigin: React.Dispatch<React.SetStateAction<string | null>>;
  skipFirstPage: number | undefined;
  setSkipFirstPage: React.Dispatch<React.SetStateAction<number|undefined>>;
}

interface MarketResearchContextType {
  marketResearchCompanies: any[];
  setMarketResearchCompanies: React.Dispatch<React.SetStateAction<any[]>>;
  marketResearchPrompt: string;
  setMarketResearchPrompt: React.Dispatch<React.SetStateAction<string>>;
  quadrantCuts: {x_cut: number, y_cut: number};
  setQuadrantCuts: React.Dispatch<React.SetStateAction<{x_cut: number, y_cut: number}>>;
  axisRanges: {x_min: number, x_max: number, y_min: number, y_max: number};
  setAxisRanges: React.Dispatch<React.SetStateAction<{x_min: number, x_max: number, y_min: number, y_max: number}>>;
}

interface StakeholderIdentificationContextType {
  stakeholderResponse: StakeholderData[];
  setStakeholderResponse: React.Dispatch<React.SetStateAction<StakeholderData[]>>;
  selectedStakeholder: StakeholderData | null;
  setSelectedStakeholder: React.Dispatch<React.SetStateAction<StakeholderData | null>>;
  selectedStakeholderOrigin: string | null;
  setSelectedStakeholderOrigin: React.Dispatch<React.SetStateAction<string | null>>;
}

interface ProjectContextType {
  defaultProjectId: string;
  setDefaultProjectId: React.Dispatch<React.SetStateAction<string>>;
  defaultProjectName: string;
  setDefaultProjectName: React.Dispatch<React.SetStateAction<string>>;
  selectedProjectId: string | null;
  setSelectedProjectId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const TechScoutingContext = createContext<TechScoutingContextType | undefined>(undefined);
export const MarketResearchContext = createContext<MarketResearchContextType | undefined>(undefined);
export const StakeholderIdentificationContext = createContext<StakeholderIdentificationContextType | undefined>(undefined);
export const ProjectContext = React.createContext<ProjectContextType | null>(null);

// **Custom Hook for ProjectContext**
export const useProjectContext = (): ProjectContextType => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjectContext must be used within a ProjectContext.Provider');
  }
  return context;
};

// **Custom Hook for MarketResearchContext**
export const useMarketResearchContext = (): MarketResearchContextType => {
  const context = useContext(MarketResearchContext);
  if (!context) {
    throw new Error('useMarketResearchContext must be used within a MarketResearchContext.Provider');
  }
  return context;
};
