import React, { useState, useEffect, useRef, useContext } from 'react';
import './style/PromptInputModal.css';
import { TechScoutingContext } from '../ContextStore';

interface PromptInputModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (prompt: string) => void;
  initialPrompt: string;
  isDarkMode: boolean;
  isTechScouting?: boolean; // Flag to indicate if this modal is used in TechScouting component
}

const PromptInputModal: React.FC<PromptInputModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialPrompt,
  isDarkMode,
  isTechScouting = false // Default to false
}) => {
  const [prompt, setPrompt] = useState(initialPrompt);
  const modalRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  
  // Always access the TechScoutingContext unconditionally
  const techScoutingContext = useContext(TechScoutingContext);
  
  // Update prompt state when initialPrompt changes
  useEffect(() => {
    setPrompt(initialPrompt);
  }, [initialPrompt]);

  // Focus the textarea when the modal opens
  useEffect(() => {
    if (isOpen && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isOpen]);
  
  // Handle keyboard shortcuts
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // Submit on Enter (without Shift for new line)
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  // Handle click outside to close modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  // Handle escape key to close modal
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isOpen, onClose]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // If we're in the TechScouting component and have access to the context
    if (isTechScouting && techScoutingContext) {
      console.log('PromptInputModal: Updating TechScoutingContext with new prompt:', prompt);
      
      // Update the techResponse in the context directly
      techScoutingContext.setTechResponse((prev: any) => {
        if (!prev) return { query: prompt, results: [] };
        return { ...prev, query: prompt };
      });
    }
    
    // Call the onSubmit prop with the new prompt
    onSubmit(prompt);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="prompt-modal-overlay">
      <div 
        ref={modalRef}
        className={`prompt-modal-container ${isDarkMode ? 'dark' : 'light'}`}
        role="dialog"
        aria-modal="true"
        aria-labelledby="prompt-modal-title"
      >
        <div className="prompt-modal-header">
          <h2 id="prompt-modal-title">Edit Search Prompt</h2>
          <button 
            className="prompt-modal-close-button" 
            onClick={onClose}
            aria-label="Close"
          >
            ×
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="prompt-modal-body">
            <label htmlFor="prompt-textarea" className="visually-hidden">
              Search Prompt
            </label>
            <textarea
              id="prompt-textarea"
              ref={textareaRef}
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder="Enter your search prompt here..."
              className={`prompt-modal-textarea ${isDarkMode ? 'dark' : 'light'}`}
              rows={8}
            />
          </div>
          <div className="prompt-modal-footer">
            <button 
              type="button" 
              className={`prompt-modal-cancel-button ${isDarkMode ? 'dark' : 'light'}`} 
              onClick={onClose}
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className={`prompt-modal-submit-button ${isDarkMode ? 'dark' : 'light'}`}
              disabled={!prompt.trim()}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PromptInputModal;
