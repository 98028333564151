// CompanyDetails.tsx

import React, { useContext, useEffect, useState } from 'react';
import { TechScoutingContext } from '../ContextStore';
import { useProjectContext } from '../ContextStore';
import './style/CompanyDetails.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';
import axiosRequest from '../utils/axiosRequest';
import { CompanyDetailsCompany, FoundingDate, Tag, SbirAward } from '../types';
import { Bar } from 'react-chartjs-2';
import Loading from './loading/Loading';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface CompanyDetailsProps {
  isDarkMode: boolean;
}

const CompanyDetails: React.FC<CompanyDetailsProps> = ({ isDarkMode }) => {
  const navigate = useNavigate();
  const techScoutingContext = useContext(TechScoutingContext);
  const projectContext = useProjectContext();
  const [harmonicData, setHarmonicData] = useState<any>(null);
  const [sbirData, setSbirData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [companyDetailsCompany, setCompanyDetailsCompany] = useState<CompanyDetailsCompany>({
    company: '',
    abstract: '',
    location: '',
    funding_total: null,
    founding_date: null,
    contact_email: null,
    contact_phone: null,
    url: '',
    source: '',
    logo_url: null,
    tags: [],
    total_award_value: undefined,
    number_phase_i_awards: undefined,
    total_phase_i_award_value: undefined,
    number_phase_ii_awards: undefined,
    total_phase_ii_award_value: undefined,
    most_recent_award_date: undefined,
    awards: []
  });

  const techURL = process.env.REACT_APP_TECH_SCOUTING_API_URL;
  if (!techURL) {
    throw new Error('API URLs are not defined');
  }
  
  if (!techScoutingContext) {
    throw new Error('TechScoutingContext not found');
  }
  const { selectedCompany, setSelectedCompany, selectedCompanyOrigin } = techScoutingContext;
  const { selectedProjectId } = projectContext;

  // Function to save company to the project
  const handleSaveCompany = async () => {
    if (!selectedProjectId) {
      setError('No project selected. Please select a project first.');
      return;
    }

    try {
      const companyData = {
        name: companyDetailsCompany.company,
        abstract: companyDetailsCompany.abstract,
        harmonic_company_id: companyDetailsCompany.harmonic_company_id,
        sbir_company_id: companyDetailsCompany.sbir_company_id,
        source: companyDetailsCompany.source
      };

      await axiosRequest(
        `${process.env.REACT_APP_PROJECTS_API_URL}${selectedProjectId}/techcompanies/save/`,
        'post',
        companyData
      );

      // Update local state to show the company is saved
      setIsSaved(true);
    } catch (error) {
      console.error('Error saving company:', error);
      setError('Failed to save company. Please try again.');
    }
  };

  useEffect(() => {
    // Only proceed if we have Harmonic or SBIR data
    if (!harmonicData && !sbirData) return;
    
    console.log('Merging company data from Harmonic and SBIR sources');
    console.log('Harmonic data:', harmonicData);
    console.log('SBIR data:', sbirData);
    
    // Helper function to check if a value is empty or "No Data"
    const isEmptyOrNoData = (value: any): boolean => {
      if (value === null || value === undefined) return true;
      if (typeof value === 'string' && (value.trim() === '' || value.trim() === 'No Data')) return true;
      if (Array.isArray(value) && value.length === 0) return true;
      return false;
    };
    
    // Helper function to combine contact information from multiple sources
    const combineContactInfo = (
      selectedValue: string | string[] | null,
      harmonicValue: string | string[] | null,
      sbirValue: string | string[] | null
    ): string[] | null => {
      const uniqueValues = new Set<string>();
      
      console.log('Combining values:', { selectedValue, harmonicValue, sbirValue });
      
      // Helper to add values to the set, handling different input types
      const addToSet = (value: any) => {
        if (!isEmptyOrNoData(value)) {
          if (Array.isArray(value)) {
            value.forEach(val => {
              if (typeof val === 'string' && val.trim() !== '' && val.trim() !== 'No Data') {
                uniqueValues.add(val.trim());
                console.log('Added array value:', val.trim());
              }
            });
          } else if (typeof value === 'string' && value.trim() !== '' && value.trim() !== 'No Data') {
            uniqueValues.add(value.trim());
            console.log('Added string value:', value.trim());
          }
        }
      };
      
      // Add values from each source
      addToSet(selectedValue);
      addToSet(harmonicValue);
      addToSet(sbirValue);
      
      console.log('Unique values collected:', Array.from(uniqueValues));
      
      // Return null if no valid values were found
      if (uniqueValues.size === 0) {
        return null;
      }
      
      // Convert Set to Array
      return Array.from(uniqueValues);
    };
    
    // Initialize the merged company with empty values
    const mergedCompany: CompanyDetailsCompany = {
      company: '',
      abstract: '',
      location: '',
      funding_total: null,
      founding_date: null,
      contact_email: null,
      contact_phone: null,
      url: '',
      source: '',
      logo_url: null,
      tags: []
    };
    
    // Get the selected company from context
    const selectedCompany = techScoutingContext?.selectedCompany || null;
    
    // If we have a selected company, use its company name and abstract as fallbacks
    if (selectedCompany) {
      if (!isEmptyOrNoData(selectedCompany.company)) {
        mergedCompany.company = selectedCompany.company as string;
      }
      if (!isEmptyOrNoData(selectedCompany.abstract)) {
        mergedCompany.abstract = selectedCompany.abstract as string;
      }
    }
    
    // Extract Harmonic data if available
    if (harmonicData) {
      console.log('Applying Harmonic data to merged company:', harmonicData);
      
      // Extract the actual data from the response - handle different response structures
      const harmonicRawData = harmonicData.data || harmonicData;
      
      // Update fields with Harmonic data if available and not "No Data"
      if (!isEmptyOrNoData(harmonicRawData.company)) mergedCompany.company = harmonicRawData.company || '';
      if (!isEmptyOrNoData(harmonicRawData.abstract)) mergedCompany.abstract = harmonicRawData.abstract || '';
      if (!isEmptyOrNoData(harmonicRawData.location)) mergedCompany.location = harmonicRawData.location || '';
      if (!isEmptyOrNoData(harmonicRawData.funding_total)) mergedCompany.funding_total = harmonicRawData.funding_total || null;
      if (!isEmptyOrNoData(harmonicRawData.founding_date)) mergedCompany.founding_date = harmonicRawData.founding_date || null;
      if (!isEmptyOrNoData(harmonicRawData.url)) mergedCompany.url = harmonicRawData.url || '';
      if (!isEmptyOrNoData(harmonicRawData.logo_url)) mergedCompany.logo_url = harmonicRawData.logo_url || null;
      
      // Use Harmonic tags if they exist
      if (!isEmptyOrNoData(harmonicRawData.tags)) {
        mergedCompany.tags = harmonicRawData.tags || [];
      }
      
      // Log the extracted data
      console.log('Extracted Harmonic data:', {
        company: harmonicRawData.company,
        location: harmonicRawData.location,
        funding_total: harmonicRawData.funding_total,
        founding_date: harmonicRawData.founding_date,
        url: harmonicRawData.url,
        logo_url: harmonicRawData.logo_url,
        tags: harmonicRawData.tags
      });
    }
    
    // Extract SBIR data if available
    if (sbirData) {
      console.log('Applying SBIR data to merged company:', sbirData);
      
      // Extract the actual data from the response - handle different response structures
      const sbirRawData = sbirData.data || sbirData;
      
      // Extract SBIR-specific fields
      if (!isEmptyOrNoData(sbirRawData.total_award_value)) mergedCompany.total_award_value = sbirRawData.total_award_value;
      if (!isEmptyOrNoData(sbirRawData.number_phase_i_awards)) mergedCompany.number_phase_i_awards = sbirRawData.number_phase_i_awards;
      if (!isEmptyOrNoData(sbirRawData.total_phase_i_award_value)) mergedCompany.total_phase_i_award_value = sbirRawData.total_phase_i_award_value;
      if (!isEmptyOrNoData(sbirRawData.number_phase_ii_awards)) mergedCompany.number_phase_ii_awards = sbirRawData.number_phase_ii_awards;
      if (!isEmptyOrNoData(sbirRawData.total_phase_ii_award_value)) mergedCompany.total_phase_ii_award_value = sbirRawData.total_phase_ii_award_value;
      if (!isEmptyOrNoData(sbirRawData.most_recent_award_date)) mergedCompany.most_recent_award_date = sbirRawData.most_recent_award_date;
      if (!isEmptyOrNoData(sbirRawData.awards)) mergedCompany.awards = sbirRawData.awards;
      
      // Only use SBIR data for these fields if Harmonic data is not available
      if (isEmptyOrNoData(mergedCompany.company) && !isEmptyOrNoData(sbirRawData.company)) 
        mergedCompany.company = sbirRawData.company || '';
      if (isEmptyOrNoData(mergedCompany.abstract) && !isEmptyOrNoData(sbirRawData.abstract)) 
        mergedCompany.abstract = sbirRawData.abstract || '';
      
      // Handle location data from SBIR if Harmonic location is not available
      if (isEmptyOrNoData(mergedCompany.location)) {
        // Construct location from SBIR address components
        const addressParts = [];
        
        // Add address1 if available
        if (!isEmptyOrNoData(sbirRawData.address1) && sbirRawData.address1 !== '0') {
          addressParts.push(sbirRawData.address1);
        }
        
        // Add city if available
        if (!isEmptyOrNoData(sbirRawData.city) && sbirRawData.city !== '0') {
          addressParts.push(sbirRawData.city);
        }
        
        // Add state if available
        if (!isEmptyOrNoData(sbirRawData.state) && sbirRawData.state !== '0') {
          addressParts.push(sbirRawData.state);
        }
        
        // Combine all parts into a single location string
        if (addressParts.length > 0) {
          mergedCompany.location = addressParts.join(', ');
        }
      }
    }
    
    // Check if SBIR data actually contains valid data and not just an error response
    const hasSbirValidData = sbirData && 
                           !(sbirData.error || 
                             (sbirData.data === null) || 
                             (sbirData.sbirRawData && sbirData.sbirRawData.error) ||
                             (sbirData.sbirRawData && sbirData.sbirRawData.data === null));
    
    // Determine the source field based on available data
    if (harmonicData && hasSbirValidData) {
      mergedCompany.source = 'Harmonic & SBIR';
    } else if (harmonicData) {
      mergedCompany.source = 'Harmonic';
    } else if (hasSbirValidData) {
      mergedCompany.source = 'SBIR';
    } else {
      mergedCompany.source = '';
    }
    
    // Extract contact information from each source
    const harmonicRawData = harmonicData?.data || harmonicData || {};
    const sbirRawData = sbirData?.data || sbirData || {};
    
    mergedCompany.contact_email = combineContactInfo(
      null,
      harmonicRawData.contact_email,
      sbirRawData.contact_email
    );
    
    mergedCompany.contact_phone = combineContactInfo(
      null,
      harmonicRawData.contact_phone,
      sbirRawData.contact_phone
    );
    
    // Update state with the merged data
    setCompanyDetailsCompany(mergedCompany);
    
  }, [harmonicData, sbirData, techScoutingContext]);

  useEffect((): (() => void) | void => {
    // Only proceed if we have a selected company
    if (!selectedCompany) return;
    
    let eventSource: EventSource | null = null;
    
    const fetchCompanyDetails = async (): Promise<void> => {
      setIsLoading(true);
      setError(null);
      
      try {
        console.log('Fetching company details for:', selectedCompany);
        
        // Extract company IDs based on source
        const harmonicCompanyId = selectedCompany.harmonic_company_id;
        const sbirCompanyId = selectedCompany.sbir_company_id;
        
        console.log('Company IDs:', { harmonicCompanyId, sbirCompanyId });
        
        // Only proceed if we have at least one ID
        if (!harmonicCompanyId && !sbirCompanyId) {
          setError('No company IDs available to fetch details');
          setIsLoading(false);
          return;
        }
        
        // Step 1: Make POST request to get temporary token
        const response = await axiosRequest(
          `${techURL}company-details/`,
          'post',
          {
            harmonic_company_id: harmonicCompanyId,
            sbir_company_id: sbirCompanyId,
            startup_flow: selectedCompany.source === 'Startup' // Set to true if coming from the tech scouting startup flow
          }
        );
        
        if (!response.data || !response.data.temp_token) {
          throw new Error('Failed to obtain temporary token');
        }
        
        const tempToken = response.data.temp_token;
        
        // Step 2: Establish SSE connection
        const sseUrl = `${techURL}company-details/?temp_token=${tempToken}`;
        eventSource = new EventSource(sseUrl);
        
        // Handle different event types
        eventSource.addEventListener('harmonic_company', (event: MessageEvent) => {
          try {
            const data = JSON.parse(event.data);
            console.log('Parsed Harmonic company data:', data);
            
            // Check if data has the expected structure
            if (data) {
              setHarmonicData(data);
            }
          } catch (e: any) {
            console.error('Error parsing Harmonic company data:', e);
          }
        });
        
        eventSource.addEventListener('sbir_company', (event: MessageEvent) => {
          try {
            const data = JSON.parse(event.data);
            console.log('Parsed SBIR company data:', data);
            
            // Check if data has the expected structure
            if (data) {
              setSbirData(data);
            }
          } catch (e: any) {
            console.error('Error parsing SBIR company data:', e);
          }
        });
        
        eventSource.addEventListener('error', (event: Event) => {
          console.error('SSE connection error:', event);
          setError('Error fetching company details');
          if (eventSource) {
            eventSource.close();
          }
          setIsLoading(false);
        });
        
        eventSource.addEventListener('end', () => {
          console.log('Company details fetch complete');
          if (eventSource) {
            eventSource.close();
          }
          setIsLoading(false);
        });
        
      } catch (error: any) {
        console.error('Error fetching company details:', error);
        setError('Failed to fetch company details');
        setIsLoading(false);
      }
    };
    
    fetchCompanyDetails();
    
    // Clean up function
    return () => {
      console.log('Cleaning up SSE connection');
      if (eventSource) {
        eventSource.close();
      }
    };
  }, [selectedCompany]);

  // Check if the company is already saved when the component loads
  useEffect(() => {
    const checkIfCompanySaved = async () => {
      if (!selectedProjectId || !companyDetailsCompany.company) return;
      
      try {
        // Fetch saved tech companies for the current project
        const response = await axiosRequest(
          `${process.env.REACT_APP_PROJECTS_API_URL}${selectedProjectId}/techcompanies/`,
          'get'
        );
        
        // Check if the current company is in the list of saved companies
        const savedCompanies = response.data || [];
        const isAlreadySaved = savedCompanies.some(
          (company: any) => company.name === companyDetailsCompany.company
        );
        
        setIsSaved(isAlreadySaved);
      } catch (error) {
        console.error('Error checking if company is saved:', error);
      }
    };
    
    checkIfCompanySaved();
  }, [selectedProjectId, companyDetailsCompany.company]);

  if (!selectedCompany) {
    return (
      <div className={`company-details-container ${isDarkMode ? 'dark' : 'light'}`}>
        <h1>Company Details Not Available</h1>
        <button
          onClick={() => navigate('/techscouting')}
          className={`company-details-back-button ${isDarkMode ? 'dark' : 'light'}`}
        >
          Go Back
        </button>
      </div>
    );
  }

  // Destructure the selectedCompany for easier access
  const {
    company,
    abstract,
    source,
    id,
    harmonic_company_id,
    sbir_company_id
  } = selectedCompany;

  // Function to format the founding date based on granularity
  const formatFoundingDate = (dateString: string, granularity: string): string => {
    const date = new Date(dateString);
    switch (granularity) {
      case 'YEAR':
        return date.getFullYear().toString();
      case 'MONTH':
        return `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;
      case 'DAY':
      default:
        return date.toLocaleDateString();
    }
  };

  // Helper function to format contact info
  const formatContactInfo = (info: string[] | null): string => {
    if (!info || info.length === 0) return 'No Data';
    
    // Log what we're formatting
    console.log('Formatting contact info:', info);
    
    // Join the array with commas
    return info.join(', ');
  };

  // Handle Go Back button click
  const handleGoBack = () => {
    setSelectedCompany(null); // Clear the selected company
    if (selectedCompanyOrigin === 'ProjectDashboard') {
      navigate('/dashboard');
    } else {
      // Default to TechScouting
      navigate('/techscouting');
    }
  };

  // Function to prepare chart data from awards
  const prepareAwardChartData = () => {
    if (!companyDetailsCompany.awards || companyDetailsCompany.awards.length === 0) {
      return null;
    }

    // Group awards by year and sum the award amounts
    const awardsByYear = companyDetailsCompany.awards.reduce((acc, award) => {
      const year = award.award_year;
      if (!acc[year]) {
        acc[year] = 0;
      }
      acc[year] += award.award_amount;
      return acc;
    }, {} as Record<number, number>);

    // Sort years in ascending order
    const years = Object.keys(awardsByYear).map(Number).sort((a, b) => a - b);
    
    // Prepare data for Chart.js
    const chartData = {
      labels: years.map(year => year.toString()),
      datasets: [
        {
          label: 'Award Amount ($)',
          data: years.map(year => awardsByYear[year]),
          backgroundColor: isDarkMode ? 'rgba(63, 189, 230, 0.7)' : 'rgba(54, 162, 235, 0.7)',
          borderColor: isDarkMode ? 'rgba(63, 189, 230, 1)' : 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
          barPercentage: 0.6, // Makes bars thinner (default is 0.9)
          categoryPercentage: 0.6, // Controls spacing between groups (default is 0.8)
        },
      ],
    };

    return chartData;
  };

  // Chart options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(this: any, tickValue: number | string) {
            // Ensure we're working with a number
            const value = typeof tickValue === 'string' ? parseFloat(tickValue) : tickValue;
            return '$' + value.toLocaleString();
          },
          color: isDarkMode ? '#aaa' : '#666',
        },
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        },
      },
      x: {
        ticks: {
          color: isDarkMode ? '#aaa' : '#666',
        },
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'SBIR Award Funding by Year',
        color: isDarkMode ? '#fff' : '#333',
        font: {
          size: 16,
        },
        padding: {
          top: 10,
          bottom: 80 // Increase bottom padding to create more space between title and chart
        }
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            return `$${context.raw.toLocaleString()}`;
          },
        },
      },
    },
  } as const;

  // Prepare chart data
  const awardChartData = prepareAwardChartData();

  return (
    <div className={`company-details-container ${isDarkMode ? 'dark' : 'light'}`}>
      <div className="company-header">
        {/* Company Logo or Initial */}
        {companyDetailsCompany.logo_url ? (
          <img src={companyDetailsCompany.logo_url} alt={`${companyDetailsCompany.company} logo`} className="company-logo" />
        ) : (
          <div className={`company-logo ${isDarkMode ? 'dark' : 'light'}`}>
            {companyDetailsCompany.company ? companyDetailsCompany.company.charAt(0).toUpperCase() : '?'}
          </div>
        )}
        
        <div className="company-header-content">
          {/* Company Name, Source, and Data Flow on the same line */}
          <div className="company-title-row">
            <span className="company-name">{companyDetailsCompany.company}</span>
            <span className={`company-source ${isDarkMode ? 'dark' : 'light'}`}>
              Source: {companyDetailsCompany.source}
            </span>
            <span className={`company-location ${isDarkMode ? 'dark' : 'light'}`}>
              <svg className="location-icon" viewBox="0 0 24 24" width="16" height="16">
                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
              </svg>
              {companyDetailsCompany.location || 'Location unknown'}
            </span>
          </div>
          
          {/* Tags row */}
          {companyDetailsCompany.tags && companyDetailsCompany.tags.length > 0 && (
            <div className="company-tags">
              {companyDetailsCompany.tags.map((tag, index) => (
                <span key={index} className={`company-tag ${isDarkMode ? 'dark' : 'light'}`}>
                  {typeof tag === 'string' ? tag : tag.display_value}
                </span>
              ))}
            </div>
          )}
        </div>
        
        {/* Go Back button on the right side */}
        <div className="header-right">
          <button
            onClick={handleSaveCompany}
            className={`company-details-save-button ${isDarkMode ? 'dark' : 'light'}`}
            disabled={isSaved}
          >
            {isSaved ? 'Saved' : 'Save to Project'}
          </button>
          <button
            onClick={handleGoBack}
            className={`company-details-back-button ${isDarkMode ? 'dark' : 'light'}`}
          >
            Go Back
          </button>
        </div>
      </div>
      
      <main role="main" tabIndex={-1} className="main-content">
        {isLoading && <Loading />}
        {error && <div className="error-message">{error}</div>}
        
        <p>
          <strong>Description:</strong> {companyDetailsCompany.abstract}
        </p>
        <p>
          <strong>Venture Funding:</strong>{' '}
          {companyDetailsCompany.funding_total !== null && companyDetailsCompany.funding_total !== undefined
            ? `$${companyDetailsCompany.funding_total.toLocaleString()}`
            : 'No Data'}
        </p>
        <p>
          <strong>Founding Date:</strong>{' '}
          {companyDetailsCompany.founding_date
            ? formatFoundingDate(companyDetailsCompany.founding_date.date, companyDetailsCompany.founding_date.granularity)
            : 'No Data'}
        </p>
        <p>
          <strong>Contact Email:</strong> {formatContactInfo(companyDetailsCompany.contact_email as string[] | null)}
        </p>
        <p>
          <strong>Contact Phone:</strong> {formatContactInfo(companyDetailsCompany.contact_phone as string[] | null)}
        </p>
        <p>
          <strong>Website:</strong> {companyDetailsCompany.url ? (
            <a href={companyDetailsCompany.url} target="_blank" rel="noopener noreferrer">
              {companyDetailsCompany.url}
            </a>
          ) : (
            'No Data'
          )}
        </p>
        
        {/* SBIR Data Section */}
        {(companyDetailsCompany.total_award_value !== undefined || 
          companyDetailsCompany.number_phase_i_awards !== undefined || 
          companyDetailsCompany.number_phase_ii_awards !== undefined || 
          companyDetailsCompany.most_recent_award_date !== undefined) && (
          <div className="sbir-data-section">
            <h2>SBIR Data:</h2>
            <div className="sbir-cards">
              {/* Total Award Funding Card */}
              <div className="sbir-card">
                <h3>Total Award Funding</h3>
                <p className="sbir-value">
                  {companyDetailsCompany.total_award_value !== undefined
                    ? `$${companyDetailsCompany.total_award_value.toLocaleString()}`
                    : 'No Data'}
                </p>
              </div>
              
              {/* Phase I Awards Card */}
              <div className="sbir-card">
                <h3>Total Phase I Awards</h3>
                <p className="sbir-value">
                  {companyDetailsCompany.number_phase_i_awards !== undefined
                    ? companyDetailsCompany.number_phase_i_awards
                    : 'No Data'}
                </p>
                <p className="sbir-subvalue">
                  {companyDetailsCompany.total_phase_i_award_value !== undefined
                    ? `$${companyDetailsCompany.total_phase_i_award_value.toLocaleString()}`
                    : ''}
                </p>
              </div>
              
              {/* Phase II Awards Card */}
              <div className="sbir-card">
                <h3>Total Phase II Awards</h3>
                <p className="sbir-value">
                  {companyDetailsCompany.number_phase_ii_awards !== undefined
                    ? companyDetailsCompany.number_phase_ii_awards
                    : 'No Data'}
                </p>
                <p className="sbir-subvalue">
                  {companyDetailsCompany.total_phase_ii_award_value !== undefined
                    ? `$${companyDetailsCompany.total_phase_ii_award_value.toLocaleString()}`
                    : ''}
                </p>
              </div>
              
              {/* Most Recent Award Date Card */}
              <div className="sbir-card">
                <h3>Most Recent Award</h3>
                <p className="sbir-value">
                  {companyDetailsCompany.most_recent_award_date !== undefined
                    ? companyDetailsCompany.most_recent_award_date
                    : 'No Data'}
                </p>
              </div>
            </div>
          </div>
        )}
        
        {/* SBIR Awards Bar Chart */}
        {awardChartData && (
          <div className="sbir-chart-section">
            <div className="sbir-chart-container">
              <Bar data={awardChartData} options={chartOptions} />
            </div>
          </div>
        )}
        
        {/* SBIR Awards Table */}
        {companyDetailsCompany.awards && companyDetailsCompany.awards.length > 0 && (
          <div className="sbir-table-section">
            <div className="sbir-table-container">
              <table className="sbir-awards-table">
                <thead>
                  <tr>
                    <th>Award Title</th>
                    <th>Award Amount</th>
                    <th>Agency</th>
                    <th>Branch</th>
                    <th>Year</th>
                  </tr>
                </thead>
                <tbody>
                  {companyDetailsCompany.awards.map((award, index) => (
                    <tr key={award.id || index}>
                      <td>{award.award_title}</td>
                      <td>${award.award_amount.toLocaleString()}</td>
                      <td>{award.agency}</td>
                      <td>{award.branch !== "0" ? award.branch : "-"}</td>
                      <td>{award.award_year}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default CompanyDetails;
