// ProjectDashboard.tsx

import React, { useEffect, useState, useRef, useContext } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/ProjectDashboard.css';
import { ProjectData, Document, Thread, StakeholderData, CompanyData } from '../types';
import Loading from './loading/Loading';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import ProjectDashboardMarketResearch from './ProjectDashboardMarketResearch';
import {
  TechScoutingContext,
  StakeholderIdentificationContext,
  ProjectContext,
  MarketResearchContext
} from '../ContextStore';


// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
interface ProjectDashboardProps {
  isDarkMode: boolean;
  projectId: string | null;
  setSelectedProjectId: (projectId: string | null) => void;
  setSelectedLibraryProjectId: (projectId: string | null) => void; // For navigating to ProjectDetail
  updateProjectInList?: (updatedProject: ProjectData) => void;
  removeProjectFromList?: (projectId: string) => void;
}

const ProjectDashboard: React.FC<ProjectDashboardProps> = ({
  isDarkMode,
  projectId,
  setSelectedProjectId,
  setSelectedLibraryProjectId,
  updateProjectInList,
  removeProjectFromList,
}) => {
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState<ProjectData | null>(null);

  // For mini-cards
  const [techScoutingResults, setTechScoutingResults] = useState<any[]>([]);
  const [stakeholderResults, setStakeholderResults] = useState<any[]>([]);
  const [marketResearchCompanies, setMarketResearchCompanies] = useState<any[]>([]);
  const [isLoadingMarketResearch, setIsLoadingMarketResearch] = useState<boolean>(false);
  const [quadrantCuts, setQuadrantCuts] = useState<{x_cut: number, y_cut: number}>({x_cut: 0.5, y_cut: 0.5});
  const [axisRanges, setAxisRanges] = useState<{x_min: number, x_max: number, y_min: number, y_max: number}>({
    x_min: 0, x_max: 1, y_min: 0, y_max: 1
  });
  
  // For saved results
  const [savedTechCompanies, setSavedTechCompanies] = useState<any[]>([]);
  const [savedStakeholders, setSavedStakeholders] = useState<any[]>([]);

  // Mini library
  const [projectDocuments, setProjectDocuments] = useState<Document[]>([]);
  const [projectThreads, setProjectThreads] = useState<Thread[]>([]);
  const [miniActiveTab, setMiniActiveTab] = useState<'documents' | 'threads'>('threads');

  // Overall SSE loading
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const eventSourceRef = useRef<EventSource | null>(null);

  // Access contexts
  const techContext = useContext(TechScoutingContext);
  const stakeholderContext = useContext(StakeholderIdentificationContext);
  const projectContext = useContext(ProjectContext);
  const marketResearchContext = useContext(MarketResearchContext);

  if (!techContext || !stakeholderContext || !projectContext || !marketResearchContext) {
    throw new Error(
      'TechScoutingContext, StakeholderIdentificationContext, ProjectContext, and MarketResearchContext must be provided.'
    );
  }

  const {
    techResponse,
    setTechResponse,
    setSelectedCompany,
    setSelectedCompanyOrigin,
    skipFirstPage,
    setSkipFirstPage,
  } = techContext;
  const { 
    stakeholderResponse, 
    setStakeholderResponse, 
    setSelectedStakeholder,
    setSelectedStakeholderOrigin 
  } = stakeholderContext;
  const { defaultProjectId } = projectContext;
  const { 
    marketResearchCompanies: contextMarketResearchCompanies, 
    setMarketResearchCompanies: setContextMarketResearchCompanies,
    marketResearchPrompt: contextMarketResearchPrompt,
    setMarketResearchPrompt: setContextMarketResearchPrompt,
    quadrantCuts: contextQuadrantCuts,
    setQuadrantCuts: setContextQuadrantCuts,
    axisRanges: contextAxisRanges,
    setAxisRanges: setContextAxisRanges
  } = marketResearchContext;

  // Environment Variables
  const projectsURL = process.env.REACT_APP_PROJECTS_API_URL;
  const documentsURL = process.env.REACT_APP_DOCUMENTS_API_URL;
  const threadsURL = process.env.REACT_APP_THREADS_API_URL;
  const projectSupportURL = process.env.REACT_APP_PROJECT_SUPPORT_API_URL;
  if (!projectsURL || !documentsURL || !threadsURL || !projectSupportURL) {
    throw new Error('API URLs are not defined');
  }

  // ------------------ Inline-edit states & logic ------------------
  const [editingName, setEditingName] = useState<boolean>(false);
  const [editingDescription, setEditingDescription] = useState<boolean>(false);
  const [editingRequirements, setEditingRequirements] = useState<boolean>(false);
  const [editingFunding, setEditingFunding] = useState<boolean>(false);
  const [editingTechnology, setEditingTechnology] = useState<boolean>(false);

  // Temp form values
  const [tempName, setTempName] = useState<string>('');
  const [tempDescription, setTempDescription] = useState<string>('');
  const [tempRequirements, setTempRequirements] = useState<string>('');
  const [tempFunding, setTempFunding] = useState<string>('');
  const [tempTechnology, setTempTechnology] = useState<string>('');

  const [updateError, setUpdateError] = useState<string | null>(null);
  const [updateSuccess, setUpdateSuccess] = useState<string | null>(null);

  // Patch function to update project fields
  const handleUpdateProjectField = async (fieldsToUpdate: Partial<ProjectData>) => {
    if (!projectId) return;

    setUpdateError(null);
    setUpdateSuccess(null);

    try {
      const patchPayload = { ...fieldsToUpdate };
      const response = await axiosRequest(
        `${projectsURL}${projectId}/update/`,
        'patch',
        patchPayload
      );

      if (response.data?.project) {
        const updatedProject = response.data.project;
        setProjectData((prev) => (prev ? { ...prev, ...updatedProject } : updatedProject));
        setUpdateSuccess('Project updated successfully.');

        // Update the name in parent if needed
        if (fieldsToUpdate.name && updateProjectInList) {
          updateProjectInList(updatedProject);
        }

        // If technology changed, also update context + trigger SSE reload
        if ('technology' in fieldsToUpdate) {

          // Update Tech context prompt (if context not null)
          if (setTechResponse) {
            setTechResponse((prev: any) => ({
              ...prev,
              query: fieldsToUpdate.technology || '',
            }));
          }

          // Update Stakeholder context prompt (if context not null)
          if (setStakeholderResponse) {
            setStakeholderResponse((prev: any) => {
              if (!prev) return [];
              const newArr = Array.isArray(prev) ? [...prev] : [];
              (newArr as any).prompt = fieldsToUpdate.technology || '';
              return newArr;
            });
          }
          fetchProjectDashboard(true, true);
        }
      }
    } catch (err: any) {
      console.error('Error updating project field:', err);
      if (err.response?.data?.error) {
        setUpdateError(err.response.data.error);
      } else {
        setUpdateError('Error updating project.');
      }
    }
  };

  // Save/Cancel Handlers
  const saveName = () => {
    if (tempName.trim()) {
      handleUpdateProjectField({ name: tempName });
    }
    setEditingName(false);
  };
  const cancelName = () => {
    if (projectData) setTempName(projectData.name);
    setEditingName(false);
  };

  const saveDescription = () => {
    handleUpdateProjectField({ description: tempDescription });
    setEditingDescription(false);
  };
  const cancelDescription = () => {
    if (projectData) setTempDescription(projectData.description);
    setEditingDescription(false);
  };

  const saveRequirements = () => {
    handleUpdateProjectField({ requirements: tempRequirements });
    setEditingRequirements(false);
  };
  const cancelRequirements = () => {
    if (projectData) {
      setTempRequirements(projectData.requirements);
    }
    setEditingRequirements(false);
  };

  const saveFunding = () => {
    const parsedValue = parseFloat(tempFunding);
    if (!Number.isNaN(parsedValue)) {
      handleUpdateProjectField({ funding: parsedValue });
    }
    setEditingFunding(false);
  };
  const cancelFunding = () => {
    if (projectData) {
      setTempFunding(projectData.funding?.toString() || '');
    }
    setEditingFunding(false);
  };

  const saveTechnology = () => {
    handleUpdateProjectField({ technology: tempTechnology });
    setEditingTechnology(false);
  };
  const cancelTechnology = () => {
    if (projectData) {
      setTempTechnology(projectData.technology || '');
    }
    setEditingTechnology(false);
  };

  // ------------------ ProjectSupport states ------------------
  const [psDocumentType, setPsDocumentType] = useState<string>('Type');
  const [psDocumentTopic, setPsDocumentTopic] = useState<string>('');
  const [psResponse, setPsResponse] = useState<string | null>(null);
  const [psLoading, setPsLoading] = useState<boolean>(false);
  const [psError, setPsError] = useState<string | null>(null);
  const [psErrorKey, setPsErrorKey] = useState<number>(0);

  const [psShowTypeDropdown, setPsShowTypeDropdown] = useState<boolean>(false);
  const [psDocuments, setPsDocuments] = useState<Document[]>([]);
  const [psSelectedDocumentId, setPsSelectedDocumentId] = useState<string | null>(null);
  const [psSelectedDocumentTitle, setPsSelectedDocumentTitle] = useState<string>('Library');
  const [psLibraryDropdownOpen, setPsLibraryDropdownOpen] = useState<boolean>(false);
  
  // Company dropdown state for Project Support
  const [psSelectedCompanyId, setPsSelectedCompanyId] = useState<string | null>(null);
  const [psSelectedCompanyName, setPsSelectedCompanyName] = useState<string>('Company');
  const [psCompanyDropdownOpen, setPsCompanyDropdownOpen] = useState<boolean>(false);

  const psTypeDropdownRef = useRef<HTMLDivElement>(null);
  const psErrorRef = useRef<HTMLDivElement>(null);
  const psSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const psLibraryDropdownButtonRef = useRef<HTMLButtonElement>(null);
  const psTypeDropdownButtonRef = useRef<HTMLButtonElement>(null);
  const psTypeDropdownMenuRef = useRef<HTMLDivElement>(null);
  const psLibraryDropdownMenuRef = useRef<HTMLDivElement>(null);
  const psCompanyDropdownButtonRef = useRef<HTMLButtonElement>(null);
  const psCompanyDropdownMenuRef = useRef<HTMLDivElement>(null);

  const psDocumentTypes = [
    '-- No Type --',
    'Approach Analysis & Design Sprint Planning Report',
    'Innovation Request Review Report',
    'Technology Scouting Report',
    'Use Case Identification Report',
    'Phase III SBIR Sole Source Justification',
  ];

  // ------------------ Delete Project states ------------------
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [deleteSuccess, setDeleteSuccess] = useState<string | null>(null);

  // ------------------ Prompt states for Tech & Stakeholders mini-cards ------------------
  const [techPrompt, setTechPrompt] = useState<string>('');
  const [stakePrompt, setStakePrompt] = useState<string>('');
  const [marketResearchPrompt, setMarketResearchPrompt] = useState<string>(contextMarketResearchPrompt || '');

  // Fetch market research data
  const fetchMarketResearch = async (projectId: string) => {
    if (!projectId) return;
    
    setIsLoadingMarketResearch(true);
    try {
      // Get all companies from the tech scouting context
      let allCompanies: any[] = [];
      
      // First check if we have results in the techResponse context
      if (techResponse && techResponse.results && techResponse.results.length > 0) {
        console.log(`Using ${techResponse.results.length} companies from tech scouting context`);
        allCompanies = techResponse.results;
      } else if (techScoutingResults.length > 0) {
        // Fallback to local state if context is empty
        console.log(`Using ${techScoutingResults.length} companies from local state`);
        allCompanies = techScoutingResults;
      }
      
      // Map companies to the format expected by the backend
      const companies = allCompanies.map(company => ({
        company: company.company,
        source: company.source,
        harmonic_company_id: company.harmonic_company_id || null,
        sbir_company_id: company.sbir_company_id || null
      }));
      
      if (companies.length === 0) {
        console.log('No companies available for market research');
        setIsLoadingMarketResearch(false);
        return;
      }
      
      console.log(`Sending ${companies.length} companies for market research analysis`);
      
      const response = await axiosRequest(
        `${projectsURL}${projectId}/market-research/`,
        'post',
        { companies }
      );
      
      if (response.data && response.data.results) {
        console.log(`Received ${response.data.results.length} scored companies from market research`);
        setMarketResearchCompanies(response.data.results);
        setContextMarketResearchCompanies(response.data.results);
        
        // Set the market research prompt from the response
        if (response.data.prompt) {
          console.log('Setting market research prompt from response:', response.data.prompt);
          setMarketResearchPrompt(response.data.prompt);
          setContextMarketResearchPrompt(response.data.prompt);
        }
        
        // Extract and update the quadrant cuts if they exist
        if (response.data.x_cut !== undefined && response.data.y_cut !== undefined) {
          setQuadrantCuts({
            x_cut: response.data.x_cut,
            y_cut: response.data.y_cut
          });
          setContextQuadrantCuts({
            x_cut: response.data.x_cut,
            y_cut: response.data.y_cut
          });
        }
        
        // Extract and update the axis ranges if they exist
        if (response.data.x_min !== undefined && response.data.x_max !== undefined && 
            response.data.y_min !== undefined && response.data.y_max !== undefined) {
          setAxisRanges({
            x_min: response.data.x_min,
            x_max: response.data.x_max,
            y_min: response.data.y_min,
            y_max: response.data.y_max
          });
          setContextAxisRanges({
            x_min: response.data.x_min,
            x_max: response.data.x_max,
            y_min: response.data.y_min,
            y_max: response.data.y_max
          });
        }
      }
    } catch (error) {
      console.error('Error fetching market research data:', error);
    } finally {
      setIsLoadingMarketResearch(false);
    }
  };

  // Load market research data from context on mount
  useEffect(() => {
    // If we already have market research data in the context, use it
    if (contextMarketResearchCompanies && contextMarketResearchCompanies.length > 0) {
      console.log('Loading market research data from context:', contextMarketResearchCompanies.length, 'companies');
      setMarketResearchCompanies(contextMarketResearchCompanies);
      
      // Also load quadrant cuts and axis ranges if available
      if (contextQuadrantCuts) {
        setQuadrantCuts(contextQuadrantCuts);
      }
      
      if (contextAxisRanges) {
        setAxisRanges(contextAxisRanges);
      }
      
      if (contextMarketResearchPrompt) {
        setMarketResearchPrompt(contextMarketResearchPrompt);
      }
    }
  }, [contextMarketResearchCompanies, contextQuadrantCuts, contextAxisRanges, contextMarketResearchPrompt]);

  // Load tech prompt from context or project data
  useEffect(() => {
    const techCtxQuery = techResponse?.query; // might be undefined if techResponse is null
    if (techCtxQuery) {
      setTechPrompt(techCtxQuery);
    } else if (projectData?.technology) {
      // only if context has no query
      setTechPrompt(projectData.technology);
    }
  }, [techResponse, projectData?.technology]);

  // If stakeholderResponse has a "prompt", use it. Otherwise, fallback
  useEffect(() => {
    if (Array.isArray(stakeholderResponse)) {
      const stakePromptInContext = (stakeholderResponse as any)?.prompt;
      if (stakePromptInContext) {
        setStakePrompt(stakePromptInContext);
      } else if (projectData?.technology) {
        setStakePrompt(projectData.technology);
      }
    } else if (projectData?.technology) {
      // if stakeholderResponse isn't an array or is null
      setStakePrompt(projectData.technology);
    }
  }, [stakeholderResponse, projectData?.technology]);

  // ----------------------------------------------------
  // On load, fill local form values from projectData
  // ----------------------------------------------------
  useEffect(() => {
    if (projectData) {
      setTempName(projectData.name || '');
      setTempDescription(projectData.description || '');
      setTempRequirements(projectData.requirements || '');
      setTempTechnology(projectData.technology || '');
      setTempFunding(
        projectData.funding != null ? String(projectData.funding) : ''
      );
    }
  }, [projectData]);

  // ----------------------------------------------------
  // Project library data
  // ----------------------------------------------------
  const fetchProjectLibraryData = async (pid: string) => {
    try {
      const [docsResp, threadsResp] = await Promise.all([
        axiosRequest(`${documentsURL}project/${pid}/`, 'get'),
        axiosRequest(`${threadsURL}project/${pid}/`, 'get'),
      ]);

      // Sort documents by creation date (newest first)
      const sortedDocs = docsResp.data.sort(
        (a: Document, b: Document) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );

      // Sort threads by creation date (newest first)
      const sortedThreads = threadsResp.data.sort(
        (a: Thread, b: Thread) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );

      setProjectDocuments(sortedDocs);
      setProjectThreads(sortedThreads);
    } catch (err) {
      console.error('Error fetching project library data:', err);
    }
  };

  // ----------------------------------------------------
  // Project Support: fetch all documents
  // ----------------------------------------------------
  useEffect(() => {
    const fetchPsDocuments = async () => {
      try {
        const response = await axiosRequest(documentsURL, 'get');
        const sortedDocuments = response.data.sort(
          (a: Document, b: Document) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        setPsDocuments(sortedDocuments);
      } catch (err: any) {
        setPsError('Error fetching documents');
      }
    };
    fetchPsDocuments();
  }, [documentsURL]);

  // ----------------------------------------------------
  // Project Support submit handler
  // ----------------------------------------------------
  const handlePsSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPsLoading(true);
    setPsError(null);

    if (psDocumentType === 'Type' || psDocumentType === '-- No Type --') {
      setPsErrorKey((prevKey) => prevKey + 1);
      setPsError('Document type required. Please select a document type.');
      setPsLoading(false);
      return;
    }

    if (!psDocumentTopic.trim() && !psSelectedDocumentId) {
      setPsErrorKey((prevKey) => prevKey + 1);
      setPsError('Please input a document topic or select a document.');
      setPsLoading(false);
      return;
    }

    try {
      const selectedProjectId = projectId;
      const data: any = {
        documentType: psDocumentType,
        documentTopic: psDocumentTopic,
        documentId: psSelectedDocumentId,
        projectId: selectedProjectId,
        companyId: psSelectedCompanyId,
      };

      const res = await axiosRequest(projectSupportURL, 'post', data);
      setPsResponse(res.data.document);
      setPsLoading(false);

      // Refresh project library after generation
      if (selectedProjectId) {
        fetchProjectLibraryData(selectedProjectId);
      }
    } catch (error: any) {
      setPsLoading(false);
      setPsErrorKey((prevKey) => prevKey + 1);

      if (error.response?.status === 401) {
        setPsError('Unauthorized. Please log in.');
      } else {
        setPsError('Error generating document.');
      }
      console.error('Error generating document:', error);
    }
  };

  const handlePsTypeDropdownClick = () => {
    setPsShowTypeDropdown(!psShowTypeDropdown);
    setPsLibraryDropdownOpen(false);
    setPsCompanyDropdownOpen(false);
  };

  const handlePsLibraryDropdownClick = () => {
    setPsLibraryDropdownOpen(!psLibraryDropdownOpen);
    setPsShowTypeDropdown(false);
    setPsCompanyDropdownOpen(false);
  };

  const handlePsCompanyDropdownClick = () => {
    setPsCompanyDropdownOpen(!psCompanyDropdownOpen);
    setPsLibraryDropdownOpen(false);
    setPsShowTypeDropdown(false);
  };

  const handlePsTypeSelection = (type: string) => {
    setPsDocumentType(type === '-- No Type --' ? 'Type' : type);
    setPsShowTypeDropdown(false);
    
    // Reset company selection if not selecting SBIR document type
    if (type !== 'Phase III SBIR Sole Source Justification') {
      setPsSelectedCompanyId(null);
      setPsSelectedCompanyName('Company');
    }
  };

  const handlePsLibrarySelection = (doc: Document | null) => {
    if (doc) {
      setPsSelectedDocumentId(doc.id);
      setPsSelectedDocumentTitle(doc.documentTitle);
    } else {
      setPsSelectedDocumentId(null);
      setPsSelectedDocumentTitle('Library');
    }
    setPsLibraryDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        psTypeDropdownRef.current &&
        !psTypeDropdownRef.current.contains(event.target as Node) &&
        psTypeDropdownMenuRef.current &&
        !psTypeDropdownMenuRef.current.contains(event.target as Node) &&
        psLibraryDropdownMenuRef.current &&
        !psLibraryDropdownMenuRef.current.contains(event.target as Node) &&
        psCompanyDropdownMenuRef.current &&
        !psCompanyDropdownMenuRef.current.contains(event.target as Node)
      ) {
        setPsLibraryDropdownOpen(false);
        setPsShowTypeDropdown(false);
        setPsCompanyDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (psError && psErrorRef.current) {
      psErrorRef.current.focus();
    }
  }, [psError]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Type dropdown
      if (psShowTypeDropdown && psTypeDropdownMenuRef.current) {
        const items = psTypeDropdownMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex(
          (item) => item === document.activeElement
        );

        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            (items[(activeIndex + 1) % items.length] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            (
              items[
                (activeIndex - 1 + items.length) % items.length
              ] as HTMLElement
            ).focus();
            break;
          case 'Enter':
            if (activeIndex !== -1) {
              event.preventDefault();
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Escape':
            setPsShowTypeDropdown(false);
            psTypeDropdownButtonRef.current?.focus();
            break;
        }
      }

      // Library dropdown
      if (psLibraryDropdownOpen && psLibraryDropdownMenuRef.current) {
        const items = psLibraryDropdownMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex(
          (item) => item === document.activeElement
        );

        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            (items[(activeIndex + 1) % items.length] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            (
              items[
                (activeIndex - 1 + items.length) % items.length
              ] as HTMLElement
            ).focus();
            break;
          case 'Enter':
            if (activeIndex !== -1) {
              event.preventDefault();
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Escape':
            setPsLibraryDropdownOpen(false);
            psLibraryDropdownButtonRef.current?.focus();
            break;
        }
      }

      // Company dropdown
      if (psCompanyDropdownOpen && psCompanyDropdownMenuRef.current) {
        const items = psCompanyDropdownMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex(
          (item) => item === document.activeElement
        );

        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            (items[(activeIndex + 1) % items.length] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            (
              items[
                (activeIndex - 1 + items.length) % items.length
              ] as HTMLElement
            ).focus();
            break;
          case 'Enter':
            if (activeIndex !== -1) {
              event.preventDefault();
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Escape':
            setPsCompanyDropdownOpen(false);
            psCompanyDropdownButtonRef.current?.focus();
            break;
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [psShowTypeDropdown, psLibraryDropdownOpen, psCompanyDropdownOpen]);

  // ----------------------------------------------------
  // SSE-based fetchProjectDashboard
  // ----------------------------------------------------
  const fetchProjectDashboard = async (doTechSSE: boolean, doStakeSSE: boolean) => {
    if (!projectId) return;

    setLoading(true);
    setError(null);
    setIsLoadingMarketResearch(true); // Set market research loading state to true when fetching dashboard

    try {
      // 1) POST => /dashboard/ to get temp_token + project_data
      const response = await axiosRequest(`${projectsURL}${projectId}/dashboard/`, 'post');
      const { temp_token, project_data } = response.data;
      setProjectData(project_data);

      // 2) If we do not need Tech SSE, do NOT reset local or context tech data
      if (doTechSSE) {
        setTechScoutingResults([]);
        if (setTechResponse) {
          setTechResponse({ results: [], query: project_data.technology || '' });
        }
      }
      // Similarly for Stake
      if (doStakeSSE) {
        setStakeholderResults([]);
        if (setStakeholderResponse) {
          const newArr: any = [];
          (newArr as any).prompt = project_data.technology || '';
          setStakeholderResponse(newArr);
        }
      }

      // Reset market research data when technology changes
      // This ensures the axis ranges are reset for the new technology
      setMarketResearchCompanies([]);
      setContextMarketResearchCompanies([]);
      setMarketResearchPrompt('');
      setContextMarketResearchPrompt('');
      
      // Reset quadrant cuts and axis ranges
      setQuadrantCuts({ x_cut: 0.5, y_cut: 0.5 });
      setContextQuadrantCuts({ x_cut: 0.5, y_cut: 0.5 });
      setAxisRanges({ x_min: 0, x_max: 1, y_min: 0, y_max: 1 });
      setContextAxisRanges({ x_min: 0, x_max: 1, y_min: 0, y_max: 1 });

      // 3) Also fetch library data
      await fetchProjectLibraryData(projectId);

      // 4) Close any existing SSE
      if (eventSourceRef.current) {
        console.log('Closing existing EventSource before starting a new one.');
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }

      // If neither doTechSSE nor doStakeSSE is needed, skip SSE
      if (!doTechSSE && !doStakeSSE) {
        console.log('Both Tech + Stake already had context; skipping SSE...');
        setLoading(false);
        setIsLoadingMarketResearch(false); // Set market research loading state to false after skipping SSE
        return;
      }

      // 5) Start SSE
      const eventSourceUrl = `${projectsURL}${projectId}/dashboard/?temp_token=${encodeURIComponent(
        temp_token
      )}`;
      const eventSource = new EventSource(eventSourceUrl);
      eventSourceRef.current = eventSource;

      eventSource.onmessage = (evt: MessageEvent) => {
        try {
          const data = JSON.parse(evt.data);

          // Log scored companies
          if (data.type === 'scored_company' && data.results && data.results.length > 0) {
            console.log('Received scored company data:', data);
            
            // Set the market research prompt from the response
            if (data.prompt) {
              console.log('Setting market research prompt from SSE:', data.prompt);
              setMarketResearchPrompt(data.prompt);
              setContextMarketResearchPrompt(data.prompt);
            }
            
            const company = data.results[0];
            console.log('Scored company metrics:', 
                        company.ability_to_execute_score, 
                        company.completeness_of_vision_score, 
                        company.quadrant);
            
            // Log more detailed company data for debugging
            console.log('Company details:', {
              name: company.company,
              source: company.source,
              enriched: company.enriched_with_harmonic,
              headcount: company.headcount,
              web_traffic: company.web_traffic,
              funding_total: company.funding_total,
              linkedin_follower_count: company.linkedin_follower_count,
              headcount_percent_change: company.headcount_percent_change,
              stage_ordinal: company.stage_ordinal,
              highlight_sum: company.highlight_sum,
              funding_per_employee: company.funding_per_employee
            });
            
            // Update marketResearchCompanies state with the scored company data
            setMarketResearchCompanies(prevCompanies => {
              // Check if this company already exists in the array
              const existingIndex = prevCompanies.findIndex(c => 
                c.company === company.company && c.source === company.source
              );
              
              if (existingIndex >= 0) {
                // Update existing company
                const updatedCompanies = [...prevCompanies];
                updatedCompanies[existingIndex] = company;
                return updatedCompanies;
              } else {
                // Add new company
                return [...prevCompanies, company];
              }
            });
            
            // Also update the context
            setContextMarketResearchCompanies(prevCompanies => {
              // Check if this company already exists in the array
              const existingIndex = prevCompanies.findIndex(c => 
                c.company === company.company && c.source === company.source
              );
              
              if (existingIndex >= 0) {
                // Update existing company
                const updatedCompanies = [...prevCompanies];
                updatedCompanies[existingIndex] = company;
                return updatedCompanies;
              } else {
                // Add new company
                return [...prevCompanies, company];
              }
            });
            
            // Extract and update the quadrant cuts if they exist
            if (data.x_cut !== undefined && data.y_cut !== undefined) {
              setQuadrantCuts({
                x_cut: data.x_cut,
                y_cut: data.y_cut
              });
              
              // Also update the context
              setContextQuadrantCuts({
                x_cut: data.x_cut,
                y_cut: data.y_cut
              });
            }
            
            // Extract and update the axis ranges if they exist
            if (data.x_min !== undefined && data.x_max !== undefined && data.y_min !== undefined && data.y_max !== undefined) {
              setAxisRanges({
                x_min: data.x_min,
                x_max: data.x_max,
                y_min: data.y_min,
                y_max: data.y_max
              });
              
              // Also update the context
              setContextAxisRanges({
                x_min: data.x_min,
                x_max: data.x_max,
                y_min: data.y_min,
                y_max: data.y_max
              });
            }
          }

          if (Array.isArray(data.results)) {
            data.results.forEach((r: any) => {
              // Stakeholder
              if (r.organization) {
                if (doStakeSSE) {
                  const newStakeholder: StakeholderData = {
                    organization: r.organization,
                    name: r.name,
                    title: r.title,
                    email: r.email,
                    source: r.source,
                  };
                  setStakeholderResults((prev) => [...prev, newStakeholder]);
                  if (setStakeholderResponse) {
                    setStakeholderResponse((prevStake: any) => {
                      const copy = Array.isArray(prevStake) ? [...prevStake] : [];
                      copy.push(newStakeholder);
                      (copy as any).prompt = (prevStake as any)?.prompt || '';
                      return copy;
                    });
                  }
                }
              } else if (r.company) {
                // Tech item
                if (doTechSSE) {
                  setTechScoutingResults((prev) => {
                    const newResults = [...prev, r];
                    return newResults;
                  });
                  
                  // Log the source of the tech scouting result
                  console.log(`Tech Scouting Result: ${r.company} - Source: ${r.source || 'Harmonic'}`);
                  if (setTechResponse) {
                    setTechResponse((prevTech: any) => {
                      const newResults = prevTech?.results
                        ? [...prevTech.results]
                        : [];
                      newResults.push(r);
                      return {
                        ...prevTech,
                        results: newResults,
                      };
                    });
                  }
                }
              }
            });
          }
        } catch (parseError) {
          console.error('Error parsing SSE data:', parseError);
        }
      };

      // SSE 'end'
      eventSource.addEventListener('end', () => {
        eventSource.close();
        eventSourceRef.current = null;
        setLoading(false);
        setIsLoadingMarketResearch(false); // Set market research loading state to false after SSE ends
      });

      // SSE Error
      eventSource.onerror = (evt) => {
        console.error('EventSource error:', evt);
        setError('An error occurred while streaming data.');
        eventSource.close();
        eventSourceRef.current = null;
        setLoading(false);
        setIsLoadingMarketResearch(false); // Set market research loading state to false after SSE error
      };
    } catch (err: any) {
      console.error('Error fetching project dashboard:', err);
      if (err.response?.data?.error) {
        setError(err.response.data.error);
      } else {
        setError('Failed to fetch project dashboard.');
      }
      setLoading(false);
      setIsLoadingMarketResearch(false); // Set market research loading state to false after error
    }
  };

  // ----------------------------------------------------
  // Fallback fetch: basic project info
  // ----------------------------------------------------
  const fetchProjectInfoOnly = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosRequest(`${projectsURL}${projectId}/`, 'get');
      setProjectData(response.data);
    } catch (err: any) {
      setError('Failed to fetch project info.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // ----------------------------------------------------
  // Fetch and process project threads for tech scouting and stakeholders
  // ----------------------------------------------------
  const fetchAndProcessProjectThreads = async (pid: string) => {
    try {
      const response = await axiosRequest(`${threadsURL}project/${pid}/`, 'get');
      const threads = response.data;

      // Find most recent stakeholder thread
      const stakeholderThreads = threads
        .filter((thread: Thread) => thread.threadType === 'StakeholderIdentification')
        .sort((a: Thread, b: Thread) => 
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );

      // Find most recent tech scouting thread
      const techScoutingThreads = threads
        .filter((thread: Thread) => thread.threadType === 'TechScouting')
        .sort((a: Thread, b: Thread) => 
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );

      const stakeholderThread = stakeholderThreads[0] || null;
      const techScoutingThread = techScoutingThreads[0] || null;
      
      console.log('Found threads:', { 
        stakeholder: stakeholderThread?.threadType, 
        techScouting: techScoutingThread?.threadType 
      });

      return {
        stakeholderThread,
        techScoutingThread
      };
    } catch (err) {
      console.error('Error fetching project threads:', err);
      return { stakeholderThread: null, techScoutingThread: null };
    }
  };

  // ----------------------------------------------------
  // useEffect to handle project changes and context management
  // ----------------------------------------------------
  useEffect(() => {
    if (!projectId) return;

    const initializeData = async () => {
      // Always fetch project library data regardless of project change
      await fetchProjectLibraryData(projectId);
      await fetchSavedResults();

      // Check context availability (only if not switching projects)
      const techHasContextResults = techResponse?.results && techResponse.results.length > 0;
      const stakeHasContextResults = Array.isArray(stakeholderResponse) && stakeholderResponse.length > 0;

      // Case A: Project hasn't changed and contexts are available
      if (techHasContextResults && stakeHasContextResults) {
        console.log('Using existing contexts and fetching basic project info');
        fetchProjectInfoOnly();
        setTechScoutingResults(techResponse.results);
        setStakeholderResults(stakeholderResponse);
        setLoading(false);
        return;
      }

      // Case B: Check for recent threads (always do this if no context or switching projects)
      console.log('Checking for recent project threads');
      const { stakeholderThread, techScoutingThread } = await fetchAndProcessProjectThreads(projectId);
      
      let needTechSSE = true;
      let needStakeSSE = true;

      // If we have a tech scouting thread, use it
      if (techScoutingThread) {
        console.log('Using most recent tech scouting thread');
        const threadResults = JSON.parse(techScoutingThread.threadText);
        setTechScoutingResults(threadResults.results || threadResults);
        if (setTechResponse) {
          setTechResponse({
            results: threadResults.results || threadResults,
            query: techScoutingThread.threadPrompt || ''
          });
        }
        needTechSSE = false;
      }

      // If we have a stakeholder thread, use it
      if (stakeholderThread) {
        console.log('Using most recent stakeholder thread');
        const threadResults = JSON.parse(stakeholderThread.threadText);
        setStakeholderResults(threadResults);
        if (setStakeholderResponse) {
          const newArr: any = [];
          (newArr as any).prompt = stakeholderThread.threadPrompt || '';
          setStakeholderResponse(newArr);
        }
        needStakeSSE = false;
      }

      // Case C: If we still need any data, fetch it
      if (needTechSSE || needStakeSSE) {
        console.log('No threads available, fetching fresh data -> doTechSSE:', needTechSSE, ' doStakeSSE:', needStakeSSE);
        fetchProjectDashboard(needTechSSE, needStakeSSE);
      } else {
        // If we got everything from threads, just fetch basic project info
        fetchProjectInfoOnly();
        setLoading(false);
      }
    };

    initializeData();

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
    };
    // eslint-disable-next-line
  }, [projectId]);

  const fetchSavedResults = async () => {
    if (!projectId) return;

    try {
      // Fetch saved tech companies
      const techResponse = await axiosRequest(`${projectsURL}${projectId}/techcompanies/`, 'get');
      setSavedTechCompanies(techResponse.data || []);

      // Fetch saved stakeholders
      const stakeholderResponse = await axiosRequest(`${projectsURL}${projectId}/stakeholders/`, 'get');
      setSavedStakeholders(stakeholderResponse.data || []);
    } catch (error) {
      console.error('Error fetching saved results:', error);
    }
  };

  // Handle delete company
  const handleDeleteCompany = async (companyId: string) => {
    if (!projectId) return;
    
    try {
      await axiosRequest(`${projectsURL}${projectId}/techcompanies/${companyId}/delete/`, 'delete');
      // Remove from state
      setSavedTechCompanies(prev => prev.filter(company => company.id !== companyId));
    } catch (error) {
      console.error('Error deleting company:', error);
    }
  };

  // Handle delete stakeholder
  const handleDeleteStakeholder = async (stakeholderId: string) => {
    if (!projectId) return;
    
    try {
      await axiosRequest(`${projectsURL}${projectId}/stakeholders/${stakeholderId}/delete/`, 'delete');
      // Remove from state
      setSavedStakeholders(prev => prev.filter(stakeholder => stakeholder.id !== stakeholderId));
    } catch (error) {
      console.error('Error deleting stakeholder:', error);
    }
  };

  // Handle stakeholder click
  const handleStakeholderClick = (stakeholder: StakeholderData) => {
    setSelectedStakeholder({
      name: stakeholder.name,
      email: stakeholder.email,
      organization: stakeholder.organization,
      title: stakeholder.title,
      source: stakeholder.source
    });
    setSelectedStakeholderOrigin('ProjectDashboard');
    navigate('/stakeholder-details');
  };

  // ----------------------------------
  // Delete Project
  // ----------------------------------
  const handleDeleteProject = async () => {
    if (!projectId) return;
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this project? This action cannot be undone.'
    );
    if (!confirmDelete) return;

    setIsDeleting(true);
    setDeleteError(null);
    setDeleteSuccess(null);

    try {
      if (projectData?.is_default) {
        setDeleteError('Default Project cannot be deleted.');
        setIsDeleting(false);
        return;
      }
      await axiosRequest(`${projectsURL}${projectId}/delete/`, 'delete');
      setDeleteSuccess('Project deleted successfully.');

      // remove from list if possible
      if (removeProjectFromList) {
        removeProjectFromList(projectId);
      }
      setIsDeleting(false);

      setSelectedProjectId(defaultProjectId);
      navigate('/dashboard');
    } catch (err: any) {
      console.error('Error deleting project:', err);
      if (err.response?.status === 401) {
        setDeleteError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setDeleteError('Error deleting project.');
      }
      setIsDeleting(false);
    }
  };

  // ----------------------------------------------------
  // Handle error or loading states
  // ----------------------------------------------------
  if (error) {
    return (
      <div
        className={`project-dashboard-container ${
          isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
        }`}
      >
        <p
          className={`project-dashboard-error-message ${
            isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
          }`}
        >
          {error}
        </p>
      </div>
    );
  }

  if (!projectData) {
    return (
      <div
        className={`project-dashboard-container ${
          isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
        }`}
      >
        <Loading />
      </div>
    );
  }

  // Utility
  const formatDateTime = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formattedDate = date.toLocaleDateString(undefined, options);
    const formattedTime = date.toLocaleTimeString(undefined, { hour12: false });
    return `${formattedDate} ${formattedTime}`;
  };

  const modeClass = isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light';

  return (
    <div className={`project-dashboard-container ${modeClass}`}>
      <Helmet>
        <title>ProjectDashboard</title>
      </Helmet>

      {/* Inline update messages */}
      {updateError && (
        <p className="project-dashboard-inline-update-error" role="alert">
          {updateError}
        </p>
      )}
      {updateSuccess && (
        <p className="project-dashboard-inline-update-success" role="alert">
          {updateSuccess}
        </p>
      )}

      {/* ------ Inline fields for Project Name, Funding, & Technology ------ */}
      <h1 className={`project-dashboard-heading ${modeClass}`}>
        {editingName ? (
          <span className="project-dashboard-inline-edit-container">
            <input
              type="text"
              value={tempName}
              onChange={(e) => setTempName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent default
                  saveName();
                }
              }}
              className="project-dashboard-inline-input"
              aria-label="Edit project name"
            />
            <button onClick={saveName} className="project-dashboard-inline-save-button">
              Save
            </button>
            <button onClick={cancelName} className="project-dashboard-inline-cancel-button">
              Cancel
            </button>
          </span>
        ) : (
          <span className="project-dashboard-inline-display">
            {projectData.name}
            <button
              onClick={() => setEditingName(true)}
              className="project-dashboard-edit-button"
              aria-label="Edit project name"
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
          </span>
        )}
      </h1>

      <p className={`project-dashboard-text project-dashboard-funding ${modeClass}`}>
        <strong>Funding:</strong>{' '}
        {editingFunding ? (
          <span className="project-dashboard-inline-edit-container">
            <input
              type="number"
              value={tempFunding}
              onChange={(e) => setTempFunding(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  saveFunding();
                }
              }}
              className="project-dashboard-inline-input"
              aria-label="Edit project funding"
            />
            <button onClick={saveFunding} className="project-dashboard-inline-save-button">
              Save
            </button>
            <button onClick={cancelFunding} className="project-dashboard-inline-cancel-button">
              Cancel
            </button>
          </span>
        ) : (
          <span className="project-dashboard-inline-display">
            ${projectData.funding}
            <button
              onClick={() => setEditingFunding(true)}
              className="project-dashboard-edit-button"
              aria-label="Edit project funding"
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
          </span>
        )}
      </p>

      <p className={`project-dashboard-text project-dashboard-technology ${modeClass}`}>
        <strong>Technologies:</strong>{' '}
        {editingTechnology ? (
          <span className="project-dashboard-inline-edit-container">
            <input
              type="text"
              value={tempTechnology}
              onChange={(e) => setTempTechnology(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  saveTechnology();
                }
              }}
              className="project-dashboard-inline-input"
              aria-label="Edit project technologies"
            />
            <button onClick={saveTechnology} className="project-dashboard-inline-save-button">
              Save
            </button>
            <button onClick={cancelTechnology} className="project-dashboard-inline-cancel-button">
              Cancel
            </button>
          </span>
        ) : (
          <span className="project-dashboard-inline-display">
            {projectData.technology}
            <button
              onClick={() => setEditingTechnology(true)}
              className="project-dashboard-edit-button"
              aria-label="Edit project technologies"
            >
              <FontAwesomeIcon icon={faPencil} />
            </button>
          </span>
        )}
      </p>

      {/* ------ Timeline ------ */}
      <div className={`project-dashboard-timeline ${modeClass}`}>
        <ul className="project-dashboard-timeline-list">
          <li className="project-dashboard-timeline-item">
            <span className="project-dashboard-timeline-icon" aria-label="Project Initiated">
              <i className="fas fa-rocket"></i>
            </span>
            <span className="project-dashboard-timeline-content">Project Initiated</span>
          </li>
          <li className="project-dashboard-timeline-item">
            <span className="project-dashboard-timeline-icon" aria-label="Market Research">
              <i className="fas fa-search"></i>
            </span>
            <span className="project-dashboard-timeline-content">Market Research</span>
          </li>
          <li className="project-dashboard-timeline-item">
            <span className="project-dashboard-timeline-icon" aria-label="Feasibility Study">
              <i className="fas fa-users"></i>
            </span>
            <span className="project-dashboard-timeline-content">Feasibility Study</span>
          </li>
          <li className="project-dashboard-timeline-item">
            <span className="project-dashboard-timeline-icon" aria-label="Project Execution">
              <i className="fas fa-clipboard-check"></i>
            </span>
            <span className="project-dashboard-timeline-content">Project Execution</span>
          </li>
        </ul>
      </div>

      {/* ------ Description & Requirements Cards ------ */}
      <div className="project-dashboard-details-row">
        {/* Description */}
        <div
          className={`project-dashboard-detail-col ${modeClass}`}
        >
          <h3 className="project-dashboard-detail-heading">Description:</h3>
          {editingDescription ? (
            <div className="project-dashboard-inline-edit-container">
              <textarea
                value={tempDescription}
                onChange={(e) => setTempDescription(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    saveDescription();
                  }
                }}
                className="project-dashboard-inline-textarea"
                aria-label="Edit project description"
              />
              <button onClick={saveDescription} className="project-dashboard-inline-save-button">
                Save
              </button>
              <button onClick={cancelDescription} className="project-dashboard-inline-cancel-button">
                Cancel
              </button>
            </div>
          ) : (
            <>
              <p className="project-dashboard-detail-text">{projectData.description}</p>
              <button
                onClick={() => setEditingDescription(true)}
                className="project-dashboard-card-edit-button"
                aria-label="Edit project description"
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
            </>
          )}
        </div>

        {/* Requirements */}
        <div
          className={`project-dashboard-detail-col ${modeClass}`}
        >
          <h3 className="project-dashboard-detail-heading">Requirements:</h3>
          {editingRequirements ? (
            <div className="project-dashboard-inline-edit-container">
              <textarea
                value={tempRequirements}
                onChange={(e) => setTempRequirements(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    saveRequirements();
                  }
                }}
                className="project-dashboard-inline-textarea"
                aria-label="Edit project requirements"
              />
              <button onClick={saveRequirements} className="project-dashboard-inline-save-button">
                Save
              </button>
              <button onClick={cancelRequirements} className="project-dashboard-inline-cancel-button">
                Cancel
              </button>
            </div>
          ) : (
            <>
              <p className="project-dashboard-detail-text">{projectData.requirements}</p>
              <button
                onClick={() => setEditingRequirements(true)}
                className="project-dashboard-card-edit-button"
                aria-label="Edit project requirements"
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
            </>
          )}
        </div>
      </div>

      {/* ------ Tech Scouting & Market Research Row ------ */}
      <div className="project-dashboard-info-row">
        {/* Tech Scouting */}
        <div
          className={`project-dashboard-info-col ${
            isDarkMode ? 'project-dashboard-dark-card' : 'project-dashboard-light-card'
          } animate__animated animate__fadeIn`}
        >
          <div className="project-dashboard-card-header-row">
            <h2 className={`project-dashboard-subheading ${modeClass}`}>
              Tech Scouting - {techPrompt}
            </h2>
            <button
              className="project-dashboard-search-button"
              aria-label="Enlarge Tech Scouting"
              onClick={() => {
                const hasLocalResults = techScoutingResults && techScoutingResults.length > 0;
                setSkipFirstPage(hasLocalResults ? 1 : 0);
                navigate('/techscouting');
              }}
            >
              Search
            </button>
          </div>

          {loading && techScoutingResults.length === 0 ? (
            <Loading />
          ) : techScoutingResults.length === 0 ? (
            <p className="project-dashboard-no-results">
              No results returned for those technologies. Try shortening or changing
              the Project's list of technologies.
            </p>
          ) : (
            <ul
              className={`project-dashboard-results-list project-dashboard-tech-scouting-list ${modeClass}`}
            >
              {techScoutingResults.slice(0, 4).map((res, index) => (
                <li
                  key={index}
                  className="project-dashboard-result-item"
                  onClick={() => {
                    setSelectedCompany({
                      company: res.company,
                      abstract: res.abstract || '',
                      source: res.source,
                      id: res.id,
                      harmonic_company_id: res.source === 'Startup' ? res.harmonic_company_id : res.harmonic_company_id,
                      sbir_company_id: res.source === 'SBIR' ? res.sbir_company_id : res.harmonic_company_id,
                    });
                    setSelectedCompanyOrigin('ProjectDashboard');
                    navigate('/company-details');
                  }}
                >
                  {res.company || 'Unknown Company'}
                </li>
              ))}
            </ul>
          )}
        </div>
        
        {/* Market Research Quadrant */}
        <div
          className={`project-dashboard-info-col ${
            isDarkMode ? 'project-dashboard-dark-card' : 'project-dashboard-light-card'
          } animate__animated animate__fadeIn`}
        >
          <div className="project-dashboard-card-header-row">
            <h2 className={`project-dashboard-subheading ${modeClass}`}>
              Market Research {marketResearchPrompt ? `- ${marketResearchPrompt}` : 'Quadrant'}
            </h2>
            <button
              className="project-dashboard-search-button"
              aria-label="Refresh Market Research"
              onClick={() => fetchMarketResearch(projectId || '')}
              disabled={isLoadingMarketResearch}
            >
              Refresh
            </button>
          </div>

          {isLoadingMarketResearch ? (
            <Loading />
          ) : marketResearchCompanies.length === 0 ? (
            <p className="project-dashboard-no-results">
              No market research data available. Wait for tech scouting to complete or click Refresh.
            </p>
          ) : (
            <div className="project-dashboard-market-research-container">
              <ProjectDashboardMarketResearch 
                companies={marketResearchCompanies} 
                isDarkMode={isDarkMode} 
                quadrantCuts={quadrantCuts}
                axisRanges={axisRanges}
              />
              <div className="project-dashboard-market-research-stats">
                <p>
                  Leaders: {marketResearchCompanies.filter(company => 
                    company.is_scored && 
                    company.completeness_of_vision_score >= quadrantCuts.x_cut && 
                    company.ability_to_execute_score >= quadrantCuts.y_cut
                  ).length}
                </p>
                <p>
                  Challengers: {marketResearchCompanies.filter(company => 
                    company.is_scored && 
                    company.completeness_of_vision_score < quadrantCuts.x_cut && 
                    company.ability_to_execute_score >= quadrantCuts.y_cut
                  ).length}
                </p>
                <p>
                  Visionaries: {marketResearchCompanies.filter(company => 
                    company.is_scored && 
                    company.completeness_of_vision_score >= quadrantCuts.x_cut && 
                    company.ability_to_execute_score < quadrantCuts.y_cut
                  ).length}
                </p>
                <p>
                  Niche Players: {marketResearchCompanies.filter(company => 
                    company.is_scored && 
                    company.completeness_of_vision_score < quadrantCuts.x_cut && 
                    company.ability_to_execute_score < quadrantCuts.y_cut
                  ).length}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* ------ Saved Tech Scouting & Project Support Row ------ */}
      <div className="project-dashboard-info-row">
        {/* Saved Tech Scouting Card */}
        <div
          className={`project-dashboard-info-col ${
            isDarkMode ? 'project-dashboard-dark-card' : 'project-dashboard-light-card'
          } animate__animated animate__fadeIn`}
        >
          <div className="project-dashboard-card-header-row">
            <h2 className={`project-dashboard-subheading ${modeClass}`}>
              Saved Tech Scouting
            </h2>
          </div>
          <div className="project-dashboard-card-content">
            {savedTechCompanies.length > 0 ? (
              <div className="saved-results-list">
                {savedTechCompanies.map((company, index) => (
                  <div 
                    key={index} 
                    className="saved-result-item clickable"
                    onClick={() => {
                      setSelectedCompany({
                        company: company.name,
                        abstract: company.abstract || '',
                        source: company.source || 'Startup', 
                        id: company.id,
                        harmonic_company_id: company.source === 'Startup' ? company.harmonic_company_id : company.harmonic_company_id,
                        sbir_company_id: company.source === 'SBIR' ? company.sbir_company_id : company.harmonic_company_id,
                      });
                      setSelectedCompanyOrigin('ProjectDashboard');
                      navigate('/company-details');
                    }}
                  >
                    <span className="saved-result-dot"></span>
                    <div className="saved-result-text">
                      <div className="saved-result-content">
                        <h4>{company.name}</h4>
                        <button
                          className="saved-result-delete-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteCompany(company.id);
                          }}
                        >
                          ×
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="no-results">No saved companies yet</p>
            )}
          </div>
        </div>

        {/* Project Support */}
        <div
          className={`project-dashboard-info-col project-dashboard-project-support-col ${
            isDarkMode ? 'project-dashboard-dark-card' : 'project-dashboard-light-card'
          } animate__animated animate__fadeIn`}
        >
          <div className="project-dashboard-card-header-row">
            <h2 className={`project-dashboard-subheading ${modeClass}`}>
              Project Support
            </h2>
            <button
              className="project-support-enlarge-button"
              aria-label="Enlarge Project Support"
              onClick={() => navigate('/project-support')}
            >
              ⛶
            </button>
          </div>
          <form className="project-dashboard-project-support-form" onSubmit={handlePsSubmit}>
            <div
              className="project-dashboard-project-support-input-container"
              ref={psTypeDropdownRef}
            >
              <label
                htmlFor="project-dashboard-project-support-query"
                className="project-dashboard-visually-hidden"
              >
                Document Topic
              </label>
              <input
                id="project-dashboard-project-support-query"
                type="text"
                value={psDocumentTopic}
                onChange={(e) => setPsDocumentTopic(e.target.value)}
                placeholder="Enter Additonal Context"
                className={`project-dashboard-project-support-input ${
                  isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                }`}
              />
              <div className="project-dashboard-project-support-buttons-container">
                <div className="project-dashboard-project-support-button-with-tooltip">
                  <button
                    type="button"
                    className={`project-dashboard-project-support-dropdown-button ${
                      isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                    }`}
                    onClick={handlePsTypeDropdownClick}
                    aria-expanded={psShowTypeDropdown}
                    aria-controls="project-dashboard-project-support-dropdown-menu"
                    ref={psTypeDropdownButtonRef}
                    title={psDocumentType}
                  >
                    <span className="dropdown-button-icon">&#9776;</span>
                    <span className="dropdown-button-text">{psDocumentType}</span>
                  </button>
                  <span className="project-dashboard-project-support-tooltip-text">
                    The type of document to be generated
                  </span>
                </div>
                <div className="project-dashboard-project-support-button-with-tooltip">
                  <button
                    type="button"
                    className={`project-dashboard-project-support-library-dropdown-button ${
                      isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                    }`}
                    onClick={handlePsLibraryDropdownClick}
                    aria-expanded={psLibraryDropdownOpen}
                    aria-controls="project-dashboard-project-support-library-dropdown-menu"
                    ref={psLibraryDropdownButtonRef}
                  >
                    <span className="dropdown-button-icon">&#128194;</span>
                    <span className="dropdown-button-text">{psSelectedDocumentTitle}</span>
                  </button>
                  <span className="project-dashboard-project-support-tooltip-text">
                    Select a document from your library
                  </span>
                </div>
                <div className="project-dashboard-project-support-button-with-tooltip">
                  <button
                    type="button"
                    className={`project-dashboard-project-support-company-dropdown-button ${
                      isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                    }`}
                    onClick={handlePsCompanyDropdownClick}
                    aria-expanded={psCompanyDropdownOpen}
                    aria-controls="project-dashboard-project-support-company-dropdown-menu"
                    ref={psCompanyDropdownButtonRef}
                  >
                    <span className="dropdown-button-icon">&#127970;</span>
                    <span className="dropdown-button-text">{psSelectedCompanyName}</span>
                  </button>
                  <span className="project-dashboard-project-support-tooltip-text">
                    Select a company from your project
                  </span>
                </div>
                <button
                  type="submit"
                  className={`project-dashboard-project-support-submit-button ${
                    isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                  }`}
                  aria-label="Submit Button"
                  ref={psSubmitButtonRef}
                >
                  &#x27A4;
                </button>
              </div>

              {/* Type Dropdown */}
              <div
                className={`project-dashboard-project-support-dropdown-menu ${
                  isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                } ${psShowTypeDropdown ? 'open' : 'closed'}`}
                id="project-dashboard-project-support-dropdown-menu"
                role="menu"
                ref={psTypeDropdownMenuRef}
                aria-hidden={!psShowTypeDropdown}
              >
                {psDocumentTypes.map((type) => (
                  <div
                    key={type}
                    tabIndex={0}
                    role="menuitem"
                    className={`project-dashboard-project-support-dropdown-item ${
                      isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                    }`}
                    onClick={() => handlePsTypeSelection(type)}
                  >
                    {type}
                  </div>
                ))}
              </div>

              {/* Library Dropdown */}
              <div
                className={`project-dashboard-project-support-library-dropdown-menu ${
                  isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                } ${psLibraryDropdownOpen ? 'open' : 'closed'}`}
                id="project-dashboard-project-support-library-dropdown-menu"
                role="menu"
                ref={psLibraryDropdownMenuRef}
                aria-hidden={!psLibraryDropdownOpen}
              >
                <div
                  role="menuitem"
                  tabIndex={0}
                  onClick={() => handlePsLibrarySelection(null)}
                  className={`project-dashboard-project-support-dropdown-item ${
                    isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                  }`}
                >
                  -- No Document --
                </div>
                {psDocuments.map((doc) => (
                  <div
                    key={doc.id}
                    role="menuitem"
                    tabIndex={0}
                    onClick={() => handlePsLibrarySelection(doc)}
                    className={`project-dashboard-project-support-dropdown-item ${
                      isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                    }`}
                  >
                    {doc.documentTitle}
                  </div>
                ))}
              </div>

              {/* Company Dropdown */}
              <div
                className={`project-dashboard-project-support-company-dropdown-menu ${
                  isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                } ${psCompanyDropdownOpen ? 'open' : 'closed'}`}
                id="project-dashboard-project-support-company-dropdown-menu"
                role="menu"
                ref={psCompanyDropdownMenuRef}
                aria-hidden={!psCompanyDropdownOpen}
              >
                <div
                  role="menuitem"
                  tabIndex={0}
                  onClick={() => {
                    setPsSelectedCompanyId(null);
                    setPsSelectedCompanyName('Company');
                    setPsCompanyDropdownOpen(false);
                    setTimeout(() => {
                      psSubmitButtonRef.current?.focus();
                    }, 0);
                  }}
                  className={`project-dashboard-project-support-dropdown-item ${
                    isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                  }`}
                >
                  -- No Company --
                </div>
                {savedTechCompanies.map((company) => (
                  <div
                    key={company.id}
                    role="menuitem"
                    tabIndex={0}
                    onClick={() => {
                      setPsSelectedCompanyId(company.id);
                      setPsSelectedCompanyName(company.name);
                      setPsCompanyDropdownOpen(false);
                      setTimeout(() => {
                        psSubmitButtonRef.current?.focus();
                      }, 0);
                    }}
                    className={`project-dashboard-project-support-dropdown-item ${
                      isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                    }`}
                  >
                    {company.name}
                  </div>
                ))}
              </div>
            </div>
            <div
              id="project-dashboard-project-support-error-message"
              key={psErrorKey}
              className={`project-dashboard-project-support-error ${
                psError ? 'visible' : 'hidden'
              } ${isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'}`}
              aria-live="assertive"
              role="alert"
              ref={psErrorRef}
              tabIndex={-1}
            >
              {psError}
            </div>
          </form>
          {psLoading && <Loading />}
          {psResponse && (
            <div
              className={`project-dashboard-project-support-response ${
                isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
              }`}
            >
              <h2>Document Generated!</h2>
              <a
                href={`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${psResponse}`}
                download="Generated_Document.docx"
                className={`project-dashboard-project-support-download-button ${
                  isDarkMode ? 'project-dashboard-dark' : 'project-dashboard-light'
                }`}
              >
                Download Document
              </a>
            </div>
          )}
        </div>
      </div>

      {/* ------ Stakeholders & Mini Library Row ------ */}
      <div className="project-dashboard-info-row">
        {/* Stakeholders */}
        <div
          className={`project-dashboard-info-col ${
            isDarkMode ? 'project-dashboard-dark-card' : 'project-dashboard-light-card'
          } animate__animated animate__fadeIn`}
        >
          <div className="project-dashboard-card-header-row">
            <h2 className={`project-dashboard-subheading ${modeClass} stakeholders-subheading`}>
              Stakeholders - {stakePrompt}
            </h2>
            <button
              className="project-dashboard-search-button"
              aria-label="Enlarge Stakeholders"
              onClick={() => navigate('/stakeholders')}
            >
              Search
            </button>
          </div>

          {loading && stakeholderResults.length === 0 ? (
            <Loading />
          ) : stakeholderResults.length === 0 ? (
            <p className="project-dashboard-no-results">
              No results returned for those technologies. Try shortening or changing
              the Project's list of technologies.
            </p>
          ) : (
            <ul
              className={`project-dashboard-results-list project-dashboard-stakeholders-list ${modeClass}`}
            >
              {stakeholderResults.slice(0, 4).map((res, index) => (
                <li key={index} className="project-dashboard-result-item" onClick={() => handleStakeholderClick(res)}>
                  {res.organization || 'Unknown Organization'}
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Mini Library Section */}
        <div
          className={`project-dashboard-info-col project-dashboard-mini-library-col ${
            isDarkMode ? 'project-dashboard-dark-card' : 'project-dashboard-light-card'
          } animate__animated animate__fadeIn`}
        >
          <div className={`project-dashboard-mini-library-container ${modeClass}`}>
            <div className="project-dashboard-card-header-row">
              <div className="project-dashboard-mini-library-left-group">
                <h2 className={`project-dashboard-mini-library-title ${modeClass}`}>
                  Project Library
                </h2>
                <div className="project-dashboard-mini-library-toggle-container">
                  <button
                    className={`project-dashboard-mini-library-toggle-button ${
                      miniActiveTab === 'documents' ? 'active' : ''
                    } ${modeClass}`}
                    onClick={() => setMiniActiveTab('documents')}
                  >
                    Documents
                  </button>
                  <span className="project-dashboard-mini-library-toggle-separator">/</span>
                  <button
                    className={`project-dashboard-mini-library-toggle-button ${
                      miniActiveTab === 'threads' ? 'active' : ''
                    } ${modeClass}`}
                    onClick={() => setMiniActiveTab('threads')}
                  >
                    Threads
                  </button>
                </div>
              </div>
              <button
                className="project-library-enlarge-button"
                aria-label="Enlarge Project Library"
                onClick={() => {
                  if (projectId) {
                    setSelectedLibraryProjectId(projectId);
                    navigate('/library');
                  }
                }}
              >
                ⛶
              </button>
            </div>
            <table className="project-dashboard-mini-library-table">
              <thead>
                <tr>
                  {miniActiveTab === 'documents' ? (
                    <>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Created At</th>
                    </>
                  ) : (
                    <>
                      <th>Prompt</th>
                      <th>Type</th>
                      <th>Created At</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {miniActiveTab === 'documents' ? (
                  projectDocuments.length > 0 ? (
                    projectDocuments.map((doc) => (
                      <tr key={doc.id}>
                        <td title={doc.documentTitle}>{doc.documentTitle}</td>
                        <td>{doc.documentType}</td>
                        <td>{formatDateTime(doc.created_at)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No documents found.</td>
                    </tr>
                  )
                ) : projectThreads.length > 0 ? (
                  projectThreads.map((thread) => (
                    <tr key={thread.id}>
                      <td title={thread.threadPrompt}>{thread.threadPrompt}</td>
                      <td>{thread.threadType}</td>
                      <td>{formatDateTime(thread.created_at)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3}>No threads found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* ------ Saved Stakeholders & Project Metadata Row ------ */}
      <div className="project-dashboard-info-row">
        {/* Saved Stakeholders Card */}
        <div
          className={`project-dashboard-info-col ${
            isDarkMode ? 'project-dashboard-dark-card' : 'project-dashboard-light-card'
          } animate__animated animate__fadeIn`}
        >
          <div className="project-dashboard-card-header-row">
            <h2 className={`project-dashboard-subheading ${modeClass}`}>
              Saved Stakeholders
            </h2>
          </div>
          <div className="project-dashboard-card-content">
            {savedStakeholders.length > 0 ? (
              <div className="saved-results-list">
                {savedStakeholders.map((stakeholder, index) => (
                  <div 
                    key={index} 
                    className="saved-result-item clickable"
                    onClick={() => {
                      setSelectedStakeholder({
                        name: stakeholder.name,
                        email: stakeholder.email,
                        organization: stakeholder.organization,
                        title: stakeholder.title,
                        source: stakeholder.source
                      });
                      setSelectedStakeholderOrigin('ProjectDashboard');
                      navigate('/stakeholder-details');
                    }}
                  >
                    <span className="saved-result-dot"></span>
                    <div className="saved-result-text">
                      <div className="saved-result-content">
                        <h4>{stakeholder.organization}</h4>
                        <button
                          className="saved-result-delete-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteStakeholder(stakeholder.id);
                          }}
                        >
                          ×
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="no-results">No saved stakeholders yet</p>
            )}
          </div>
        </div>

        {/* Project Metadata Card */}
        <div
          className={`project-dashboard-info-col ${
            isDarkMode ? 'project-dashboard-dark-card' : 'project-dashboard-light-card'
          } animate__animated animate__fadeIn`}
        >
          <div className="project-dashboard-card-header-row">
            <h2 className={`project-dashboard-subheading ${modeClass}`}>
              Project Metadata
            </h2>
          </div>
          <div className="project-dashboard-card-content">
            <div className="project-metadata-item">
              <strong>Created</strong>
              <div className="project-metadata-item-value">{formatDateTime(projectData.created_at)}</div>
            </div>
            <div className="project-metadata-item">
              <strong>Last Updated</strong>
              <div className="project-metadata-item-value">{formatDateTime(projectData.updated_at)}</div>
            </div>
            <div className="project-metadata-item">
              <strong>Default Project</strong>
              <div className="project-metadata-item-value">{projectData.is_default ? 'Yes' : 'No'}</div>
            </div>
          </div>
        </div>
      </div>

      {/* ------ Delete Project Section ------ */}
      <div
        className={`project-dashboard-delete-section ${modeClass} animate__animated animate__fadeIn`}
      >
        <button
          className="project-dashboard-delete-button"
          onClick={handleDeleteProject}
          disabled={isDeleting}
          aria-label="Delete Project"
        >
          {isDeleting ? 'Deleting...' : 'Delete Project'}
        </button>
        {deleteError && (
          <p className="project-dashboard-delete-error" role="alert">
            {deleteError}
          </p>
        )}
      </div>
    </div>
  );
};

export default ProjectDashboard;
