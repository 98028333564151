import React, { useState, useEffect, useRef } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/ProjectSupport.css';
import Loading from './loading/Loading';
import { Document, CompanyData } from '../types';
import { Helmet } from 'react-helmet';
import { useProjectContext } from '../ContextStore';

interface ProjectSupportProps {
  isDarkMode: boolean;
}

const ProjectSupport: React.FC<ProjectSupportProps> = ({ isDarkMode }) => {
  const [documentType, setDocumentType] = useState<string>('Type');
  const [documentTopic, setDocumentTopic] = useState<string>('');
  const [response, setResponse] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [errorKey, setErrorKey] = useState<number>(0);

  // Library dropdown state
  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null);
  const [selectedDocumentTitle, setSelectedDocumentTitle] = useState<string>('Library');
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  // Company dropdown state
  const [companies, setCompanies] = useState<CompanyData[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('Company');
  const [companyDropdownOpen, setCompanyDropdownOpen] = useState<boolean>(false);

  const projectsURL = process.env.REACT_APP_PROJECTS_API_URL;
  const projectSupportURL = process.env.REACT_APP_PROJECT_SUPPORT_API_URL;
  const documentsURL = process.env.REACT_APP_DOCUMENTS_API_URL;
  if (!projectsURL || !projectSupportURL || !documentsURL) {
    throw new Error('API URLs are not defined');
  }

  const documentTypes = [
    '-- No Type --',
    'Approach Analysis & Design Sprint Planning Report',
    'Innovation Request Review Report',
    'Technology Scouting Report',
    'Use Case Identification Report',
    "Phase III SBIR Sole Source Justification",
  ];

  const dropdownRef = useRef<HTMLDivElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const libraryDropdownButtonRef = useRef<HTMLButtonElement>(null);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null); // **Added Ref**
  const companyDropdownButtonRef = useRef<HTMLButtonElement>(null);

  // References to the dropdown menus
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const libraryDropdownMenuRef = useRef<HTMLDivElement>(null);
  const companyDropdownMenuRef = useRef<HTMLDivElement>(null);

  const { selectedProjectId } = useProjectContext();

  // Fetch documents for the library dropdown
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axiosRequest(documentsURL, 'get');
        const sortedDocuments = response.data.sort((a: Document, b: Document) => {
          return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        });
        setDocuments(sortedDocuments);
      } catch (err: any) {
        setError('Error fetching documents');
      }
    };
    fetchDocuments();
  }, [documentsURL]);

  // Fetch companies for the company dropdown
  useEffect(() => {
    const fetchCompanies = async () => {
      if (!selectedProjectId) return;
      
      try {
        const techResponse = await axiosRequest(`${projectsURL}${selectedProjectId}/techcompanies/`, 'get');
        // Map the backend data structure to match our frontend CompanyData type
        const mappedCompanies = techResponse.data.map((item: any) => ({
          ...item,
          company: item.name // Map 'name' from backend to 'company' for frontend
        }));
        setCompanies(mappedCompanies);
      } catch (err: any) {
        setError('Error fetching companies');
      }
    };
    fetchCompanies();
  }, [projectsURL, selectedProjectId]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Validate user input
    if (documentType === 'Type' || documentType === '-- No Type --') {
      setErrorKey((prevKey) => prevKey + 1);
      setError('Document type required. Please select a document type.');
      setLoading(false);
      return;
    }

    if (!documentTopic.trim() && !selectedDocumentId) {
      setErrorKey((prevKey) => prevKey + 1);
      setError('Please input a document topic or select a document.');
      setLoading(false);
      return;
    }

    try {
      // Prepare the data to send
      const data: any = {
        documentType,
        documentTopic,
        documentId: selectedDocumentId,
        companyId: selectedCompanyId,
        projectId: selectedProjectId, // Include default project ID
      };

      const res = await axiosRequest(projectSupportURL, 'post', data);
      setResponse(res.data.document);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorKey((prevKey) => prevKey + 1);

      if (error.response && error.response.status === 401) {
        setError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setError('Error generating document.');
      }

      console.error('Error generating document:', error);
    }
  };

  const handleDropdownClick = (type: string) => {
    if (type === '-- No Type --') {
      setDocumentType('Type');
    } else {
      setDocumentType(type);
    }
    setShowDropdown(false);
  };

  const handleDropdownButtonClick = () => {
    setShowDropdown(!showDropdown);
  };

  // Handle library dropdown open/close and selection
  const handleLibraryDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleLibrarySelection = (document: Document | null) => {
    if (document) {
      setSelectedDocumentId(document.id);
      setSelectedDocumentTitle(document.documentTitle);
    } else {
      setSelectedDocumentId(null);
      setSelectedDocumentTitle('Library');
    }
    setDropdownOpen(false);

    // Delay focusing on the company dropdown button
    setTimeout(() => {
      companyDropdownButtonRef.current?.focus();
    }, 0);
  };

  // Handle company dropdown open/close and selection
  const handleCompanyDropdownClick = () => {
    setCompanyDropdownOpen(!companyDropdownOpen);
  };

  const handleCompanySelection = (company: CompanyData | null) => {
    if (company) {
      setSelectedCompanyId(company.id || null);
      setSelectedCompanyName(company.company);
    } else {
      setSelectedCompanyId(null);
      setSelectedCompanyName('Company');
    }
    setCompanyDropdownOpen(false);

    // Delay focusing on the submit button
    setTimeout(() => {
      submitButtonRef.current?.focus();
    }, 0);
  };

  // Set focus to the first menu item when the document type dropdown opens
  useEffect(() => {
    if (showDropdown && dropdownMenuRef.current) {
      const firstItem = dropdownMenuRef.current.querySelector('div[role="menuitem"]');
      (firstItem as HTMLElement)?.focus();
    }
  }, [showDropdown]);

  // Set focus to the first menu item when the library dropdown opens
  useEffect(() => {
    if (dropdownOpen && libraryDropdownMenuRef.current) {
      const firstItem = libraryDropdownMenuRef.current.querySelector('div[role="menuitem"]');
      (firstItem as HTMLElement)?.focus();
    }
  }, [dropdownOpen]);

  // Set focus to the first menu item when the company dropdown opens
  useEffect(() => {
    if (companyDropdownOpen && companyDropdownMenuRef.current) {
      const firstItem = companyDropdownMenuRef.current.querySelector('div[role="menuitem"]');
      (firstItem as HTMLElement)?.focus();
    }
  }, [companyDropdownOpen]);

  // Close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
        setShowDropdown(false);
        setCompanyDropdownOpen(false);
        submitButtonRef.current?.focus();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  // Focus the error message when it appears
  useEffect(() => {
    if (error && errorRef.current) {
      errorRef.current.focus();
    }
  }, [error]);

  // Handle keyboard navigation for all dropdowns
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Handle keyboard navigation for document type dropdown
      if (showDropdown && dropdownMenuRef.current) {
        const items = dropdownMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex((item) => item === document.activeElement);

        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
            (items[nextIndex] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            const prevIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
            (items[prevIndex] as HTMLElement).focus();
            break;
          case 'Enter':
            if (activeIndex !== -1) {
              event.preventDefault();
              event.stopPropagation();
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Tab':
            if (event.shiftKey) {
              if (activeIndex === 0) {
                event.preventDefault();
                setShowDropdown(false);
                (dropdownButtonRef.current as HTMLElement)?.focus();
              }
            } else {
              if (activeIndex === items.length - 1) {
                event.preventDefault();
                setShowDropdown(false);
                (libraryDropdownButtonRef.current as HTMLElement)?.focus();
              }
            }
            break;
          case 'Escape':
            setShowDropdown(false);
            (dropdownButtonRef.current as HTMLElement)?.focus();
            break;
        }
      }

      // Handle keyboard navigation for library dropdown
      if (dropdownOpen && libraryDropdownMenuRef.current) {
        const items = libraryDropdownMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex((item) => item === document.activeElement);

        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
            (items[nextIndex] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            const prevIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
            (items[prevIndex] as HTMLElement).focus();
            break;
          case 'Enter':
            if (activeIndex !== -1) {
              event.preventDefault();
              event.stopPropagation();
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Tab':
            if (event.shiftKey) {
              if (activeIndex === 0) {
                event.preventDefault();
                setDropdownOpen(false);
                (libraryDropdownButtonRef.current as HTMLElement)?.focus();
              }
            } else {
              if (activeIndex === items.length - 1) {
                event.preventDefault();
                setDropdownOpen(false);
                (companyDropdownButtonRef.current as HTMLElement)?.focus();
              }
            }
            break;
          case 'Escape':
            setDropdownOpen(false);
            libraryDropdownButtonRef.current?.focus();
            break;
        }
      }

      // Handle keyboard navigation for company dropdown
      if (companyDropdownOpen && companyDropdownMenuRef.current) {
        const items = companyDropdownMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex((item) => item === document.activeElement);

        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
            (items[nextIndex] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            const prevIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
            (items[prevIndex] as HTMLElement).focus();
            break;
          case 'Enter':
            if (activeIndex !== -1) {
              event.preventDefault();
              event.stopPropagation();
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Tab':
            if (event.shiftKey) {
              if (activeIndex === 0) {
                event.preventDefault();
                setCompanyDropdownOpen(false);
                (companyDropdownButtonRef.current as HTMLElement)?.focus();
              }
            } else {
              if (activeIndex === items.length - 1) {
                event.preventDefault();
                setCompanyDropdownOpen(false);
                submitButtonRef.current?.focus();
              }
            }
            break;
          case 'Escape':
            setCompanyDropdownOpen(false);
            companyDropdownButtonRef.current?.focus();
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showDropdown, dropdownOpen, companyDropdownOpen]);

  return (
    <>
      <Helmet>
        <title>Project Support</title>
      </Helmet>
      <nav aria-label="Primary Navigation" className="primary-nav"></nav>
      <main role="main" tabIndex={-1} className="main-content">
        <div className={`project-support-container ${isDarkMode ? 'dark' : 'light'}`}>
          <h1 tabIndex={0} role="heading" aria-level={1} className={isDarkMode ? 'dark' : 'light'}>
            Project Support
          </h1>
          <p tabIndex={0} role="note" className={isDarkMode ? 'dark' : 'light'}>
            Generates documents of a selected document type and with an inputted AI prompt for context.
          </p>
          <form className="project-support-form" onSubmit={handleSubmit}>
            <div className="project-support-input-container" ref={dropdownRef}>
              <label htmlFor="project-support-query" className="visually-hidden">
                Document Topic
              </label>
              <input
                id="project-support-query"
                type="text"
                value={documentTopic}
                onChange={(e) => setDocumentTopic(e.target.value)}
                placeholder="Ex: Write a use case identification report for GenAI improving customer service"
                autoComplete="off"
                aria-describedby="error-message"
                className={`${isDarkMode ? 'dark' : 'light'}`}
              />
              <div className="project-support-buttons-container">
                {/* Document Type Dropdown Button with Tooltip */}
                <div className="project-support-button-with-tooltip">
                  <div className="project-support-dropdown-container">
                    <button
                      type="button"
                      className={`project-support-dropdown-button ${isDarkMode ? 'dark' : 'light'}`}
                      onClick={handleDropdownButtonClick}
                      aria-expanded={showDropdown}
                      aria-controls="project-support-dropdown-menu"
                      ref={dropdownButtonRef}
                      title={documentType}
                    >
                      <span className="dropdown-button-icon">&#9776;</span>
                      <span className="dropdown-button-text">
                        {documentType}
                      </span>
                    </button>
                  </div>
                  <span id="document-type-tooltip" className="project-support-tooltip-text">
                    The type of document to be generated
                  </span>
                </div>

                {/* Library Dropdown Button with Tooltip */}
                <div className="project-support-button-with-tooltip">
                  <div className="project-support-dropdown-container">
                    <button
                      type="button"
                      className={`project-support-library-dropdown-button ${isDarkMode ? 'dark' : 'light'}`}
                      onClick={handleLibraryDropdownClick}
                      aria-expanded={dropdownOpen}
                      aria-controls="project-support-library-dropdown-menu"
                      ref={libraryDropdownButtonRef}
                      title={selectedDocumentTitle}
                    >
                      <span className="dropdown-button-icon">&#128194;</span>
                      <span className="dropdown-button-text">
                        {selectedDocumentTitle}
                      </span>
                    </button>
                  </div>
                  <span className="project-support-tooltip-text">Select a document from your library</span>
                </div>

                {/* Company Dropdown Button with Tooltip */}
                <div className="project-support-button-with-tooltip">
                  <div className="project-support-dropdown-container">
                    <button
                      type="button"
                      className={`project-support-library-dropdown-button ${isDarkMode ? 'dark' : 'light'}`}
                      onClick={handleCompanyDropdownClick}
                      aria-expanded={companyDropdownOpen}
                      aria-controls="project-support-company-dropdown-menu"
                      ref={companyDropdownButtonRef}
                      title={selectedCompanyName}
                    >
                      <span className="dropdown-button-icon">&#127970;</span>
                      <span className="dropdown-button-text">
                        {selectedCompanyName}
                      </span>
                    </button>
                  </div>
                  <span className="project-support-tooltip-text">Select a company from your saved companies</span>
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className={`project-support-submit-button ${isDarkMode ? 'dark' : 'light'}`}
                  aria-label="Submit Button"
                  ref={submitButtonRef}
                >
                  &#x27A4;
                </button>
              </div>

              {/* Document Type Dropdown - Always Rendered */}
              <div
                className={`project-support-dropdown-menu ${isDarkMode ? 'dark' : 'light'} ${showDropdown ? 'open' : 'closed'}`}
                id="project-support-dropdown-menu"
                role="menu"
                ref={dropdownMenuRef}
              >
                {documentTypes.map((type) => (
                  <div
                    key={type}
                    tabIndex={0}
                    role="menuitem"
                    className={`project-support-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                    onClick={() => handleDropdownClick(type)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleDropdownClick(type);
                      }
                    }}
                  >
                    {type}
                  </div>
                ))}
              </div>

              {/* Library Dropdown Menu - Always Rendered */}
              <div
                className={`project-support-library-dropdown-menu ${isDarkMode ? 'dark' : 'light'} ${dropdownOpen ? 'open' : 'closed'}`}
                id="project-support-library-dropdown-menu"
                role="menu"
                ref={libraryDropdownMenuRef}
              >
                <div
                  role="menuitem"
                  tabIndex={0}
                  onClick={() => handleLibrarySelection(null)}
                  className={`project-support-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleLibrarySelection(null);
                    }
                  }}
                >
                  -- No Document --
                </div>
                {documents.map((document) => (
                  <div
                    key={document.id}
                    role="menuitem"
                    tabIndex={0}
                    onClick={() => handleLibrarySelection(document)}
                    className={`project-support-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleLibrarySelection(document);
                      }
                    }}
                  >
                    {document.documentTitle}
                  </div>
                ))}
              </div>

              {/* Company Dropdown Menu - Always Rendered */}
              <div
                className={`project-support-library-dropdown-menu ${isDarkMode ? 'dark' : 'light'} ${companyDropdownOpen ? 'open' : 'closed'}`}
                id="project-support-company-dropdown-menu"
                role="menu"
                ref={companyDropdownMenuRef}
              >
                <div
                  role="menuitem"
                  tabIndex={0}
                  onClick={() => handleCompanySelection(null)}
                  className={`project-support-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleCompanySelection(null);
                    }
                  }}
                >
                  -- No Company --
                </div>
                {companies.map((company) => (
                  <div
                    key={company.id}
                    role="menuitem"
                    tabIndex={0}
                    onClick={() => handleCompanySelection(company)}
                    className={`project-support-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleCompanySelection(company);
                      }
                    }}
                  >
                    {company.company}
                  </div>
                ))}
              </div>
            </div>
            {/* Error Message */}
            <div
              id="error-message"
              key={errorKey}
              className={`project-support-error ${error ? 'visible' : 'hidden'} ${isDarkMode ? 'dark' : 'light'}`}
              aria-live="assertive"
              role="alert"
              ref={errorRef}
              tabIndex={-1}
            >
              {error}
            </div>
          </form>
          {loading && <Loading />}
          {response && (
            <div className={`project-support-response ${isDarkMode ? 'dark' : 'light'}`}>
              <h2 tabIndex={0} role="heading" aria-level={2}>
                Document Generated!
              </h2>
              <a
                href={`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${response}`}
                download="Generated_Document.docx"
                className={`project-support-download-button ${isDarkMode ? 'dark' : 'light'}`}
              >
                Download Document
              </a>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default ProjectSupport;
