// TechScouting.tsx

import React, { useState, useRef, useEffect, useContext } from 'react';
import axiosRequest from '../utils/axiosRequest';
import './style/TechScouting.css';
import Loading from './loading/Loading';
import { Helmet } from 'react-helmet';
import { Document } from '../types';
import { TechScoutingContext, useProjectContext } from '../ContextStore';
import { useNavigate } from 'react-router-dom';
import PromptInputModal from './PromptInputModal';

interface TechScoutingProps {
  isDarkMode: boolean;
}

const TechScouting: React.FC<TechScoutingProps> = ({ isDarkMode }) => {
  // Local state declarations
  const navigate = useNavigate()
  const [query, setQuery] = useState<string>('');
  const [threshold, setThreshold] = useState<number>(0.39);
  const [loading, setLoading] = useState<boolean>(false);
  const [useSBIR, setUseSBIR] = useState<boolean>(true);
  const [useStartup, setUseStartup] = useState<boolean>(true);

  const [dataSourceDropdownOpen, setDataSourceDropdownOpen] = useState<boolean>(false);
  const [selectedDataSource, setSelectedDataSource] = useState<string>('Startup & SBIR');
  const [precisionDropdownOpen, setPrecisionDropdownOpen] = useState<boolean>(false);
  const [selectedPrecision, setSelectedPrecision] = useState<string>('Precision');

  const [documents, setDocuments] = useState<Document[]>([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null);
  const [selectedDocumentTitle, setSelectedDocumentTitle] = useState<string>('Library');
  const [libraryDropdownOpen, setLibraryDropdownOpen] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);
  const [errorKey, setErrorKey] = useState<number>(0);
  const [s3Url, setS3Url] = useState<string | null>(null);
  const [retryAttempt, setRetryAttempt] = useState<boolean>(false);
  const [savedCompanies, setSavedCompanies] = useState<Set<string>>(new Set());
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchMode, setSearchMode] = useState<'company' | 'description'>('company');
  const [isPromptModalOpen, setIsPromptModalOpen] = useState<boolean>(false);

  // Refs for accessibility and SSE
  const dropdownRef = useRef<HTMLDivElement>(null);
  const libraryDropdownButtonRef = useRef<HTMLButtonElement>(null);
  const precisionDropdownButtonRef = useRef<HTMLButtonElement>(null);
  const precisionMenuRef = useRef<HTMLDivElement>(null);
  const libraryDropdownMenuRef = useRef<HTMLDivElement>(null);
  const dataSourceDropdownButtonRef = useRef<HTMLButtonElement>(null);
  const dataSourceMenuRef = useRef<HTMLDivElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const errorRef = useRef<HTMLDivElement>(null);
  const eventSourceRef = useRef<EventSource | null>(null);

  // Cancellation flag ref. When true, we’ll cancel any streaming request.
  const cancelRequestedRef = useRef<boolean>(false);

  // API URLs
  const techURL = process.env.REACT_APP_TECH_SCOUTING_API_URL;
  const documentsURL = process.env.REACT_APP_DOCUMENTS_API_URL;
  const projectsURL = process.env.REACT_APP_PROJECTS_API_URL;
  if (!techURL || !documentsURL || !projectsURL) {
    throw new Error('API URLs are not defined');
  }

  // Context from TechScoutingContext and default project context
  const techScoutingContext = useContext(TechScoutingContext);
  if (!techScoutingContext) {
    throw new Error('TechScoutingContext not found');
  }
  const { 
    techResponse, 
    setTechResponse, 
    setSelectedCompany, 
    setSelectedCompanyOrigin,
    skipFirstPage, // a number: 0 or 1, or undefined
    setSkipFirstPage,
  } = techScoutingContext;

  const { selectedProjectId } = useProjectContext();

  // Clear context based on data source selection
  const clearContext = () => {
    if (skipFirstPage === 0 || skipFirstPage === undefined) {
      // If we're doing a completely fresh load, clear everything
      setTechResponse(null);
    }
  };

  // Auto-submit effect: if skipFirstPage is 0 or 1 and query exists, auto-submit.
  useEffect(() => {
    if ((skipFirstPage === 0 || skipFirstPage === 1) && query.trim()) {
      autoSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipFirstPage, query]);

  // Helper to "auto-submit" (without a real event)
  const autoSubmit = () => {
    // Reset the cancellation flag on every new submission.
    cancelRequestedRef.current = false;
    const mockEvent = { preventDefault: () => {} } as React.FormEvent<HTMLFormElement>;
    handleSubmit(mockEvent);
  };

  // Sync local query with techResponse.query from context
  useEffect(() => {
    if (techResponse && typeof techResponse.query === 'string') {
      setQuery(techResponse.query);
    }
  }, [techResponse]);

  // Fetch documents for library dropdown
  useEffect(() => {
    const fetchDocs = async () => {
      try {
        const response = await axiosRequest(documentsURL, 'get');
        const sortedDocs = response.data.sort(
          (a: Document, b: Document) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        setDocuments(sortedDocs);
      } catch (err: any) {
        setError('Error fetching documents');
      }
    };
    fetchDocs();
  }, [documentsURL]);

  // handleSubmit: called by form submit or auto-submit; event is optional.
  const handleSubmit = async (e?: React.FormEvent<HTMLFormElement> | boolean, generateReport: boolean = false, customQuery?: string) => {
    if (e && typeof e !== 'boolean') e.preventDefault();
    // Reset cancellation flag for this submission.
    cancelRequestedRef.current = false;
    
    // If e is a boolean, it's being used as generateReport
    if (typeof e === 'boolean') {
      generateReport = e;
    }
    
    // Use customQuery if provided, otherwise use the state query
    const queryToUse = customQuery || query;
    
    console.log('🔍 handleSubmit called with generateReport:', generateReport);
    console.log('Current state - query:', queryToUse, 'threshold:', threshold, 'useSBIR:', useSBIR, 'useStartup:', useStartup);
    
    setError(null);
    setS3Url(null);
    setRetryAttempt(false);

    // Close any existing EventSource connection
    if (eventSourceRef.current) {
      console.log('Closing existing EventSource connection before submitting new request');
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }

    // If we are in normal user flow (skipFirstPage undefined) and no query/doc provided, show error.
    if (skipFirstPage === undefined && !queryToUse.trim() && !selectedDocumentId) {
      console.error('No query or document provided');
      setErrorKey(prev => prev + 1);
      setError('Please input a query or select a document.');
      return;
    }

    setLoading(true);
    // Only clear context if we're not generating a report
    if (!generateReport) {
      clearContext();
    }

    try {
      // Determine the starting page: if skipFirstPage is defined use it; otherwise default to 0.
      const pageToStart = skipFirstPage !== undefined ? skipFirstPage : 0;
      
      // Check if there are any SBIR results in the current techResponse
      const hasSBIRResults = techResponse && 
                            techResponse.results && 
                            techResponse.results.some((result: any) => result.source === 'SBIR');
      
      // Set saveDontSendSbir to true if we're coming from ProjectDashboard AND we already have SBIR results
      const saveDontSendSbir = skipFirstPage !== undefined && hasSBIRResults ? true : false;
      console.log('Setting save_dont_send_sbir to:', saveDontSendSbir, 'type:', typeof saveDontSendSbir);
      console.log('Has SBIR results in context:', hasSBIRResults);

      const requestData = {
        query: queryToUse,
        threshold,
        generateReport,
        documentId: selectedDocumentId,
        useSBIR: useSBIR, 
        useStartup,
        projectId: selectedProjectId,
        start_page: pageToStart,
        save_dont_send_sbir: saveDontSendSbir
      };
      console.log('📤 Request data:', requestData);

      if (skipFirstPage === undefined) {
      // If we're including SBIR in our search, remove any existing SBIR results
      setTechResponse((prev: any) => {
        if (!prev || !prev.results) return prev;
        
        console.log('Filtering out existing SBIR results from context');
        const filteredResults = prev.results.filter(
          (result: { source: string }) => result.source !== 'SBIR'
        );
        
        return {
          ...prev,
          results: filteredResults
        };
      });
    }

      if (!generateReport && (useStartup || (useSBIR && useStartup))) {
        // Streaming scenario for Startup only or both Startup & SBIR
        console.log('🌊 Using streaming for request with useStartup:', useStartup, 'useSBIR:', useSBIR);
        await initiateStreaming(requestData);
      } else {
        // For SBIR only or report generation, use a normal POST request.
        console.log('📊 Using normal POST request with useStartup:', useStartup, 'useSBIR:', useSBIR);
        const res = await axiosRequest(techURL, 'post', requestData);
        console.log('📥 Response received:', res.data);
        setLoading(false);
        // Reset skipFirstPage so that future searches do not auto-load.
        setSkipFirstPage(undefined);

        if (generateReport && res.data.s3_url) {
          console.log('📄 Report generated with URL:', res.data.s3_url);
          setS3Url(res.data.s3_url);
        } else {
          console.log('Processing normal response data');
          const sanitized = sanitizeResponse(res.data);
          sanitized.query = queryToUse;
          setTechResponse(sanitized);
        }
      }
    } catch (err: any) {
      console.error('❌ Error in handleSubmit:', err);
      console.error('Error details:', err.response?.data || err.message);
      setLoading(false);
      setErrorKey(prev => prev + 1);
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else if (err.response && err.response.status === 401) {
        setError('Unauthorized. Please log in with a valid email and password.');
      } else {
        setError('Error fetching data. Please try again.');
      }
      console.error('Error fetching data:', err, 'url used:', techURL);
    }
  };

  // Streaming: initiate SSE and check for cancellation.
  const initiateStreaming = async (requestData: any, retry: boolean = false) => {
    try {
      // Check if we already have an active EventSource connection
      if (eventSourceRef.current) {
        console.warn('⚠️ Attempt to initiate streaming while an existing connection is active');
        console.log('Current EventSource readyState:', eventSourceRef.current.readyState);
        
        // If the existing connection is still active, close it first
        if (eventSourceRef.current.readyState !== EventSource.CLOSED) {
          console.log('Closing existing EventSource connection before creating a new one');
          eventSourceRef.current.close();
        }
      }
      
      console.log('🔄 Initiating streaming with request data:', requestData);
      console.log('Making POST request to get streaming token...');
      const postRes = await axiosRequest(techURL, 'post', requestData);
      console.log('POST response received:', postRes.data);
      const { temp_token, query: updatedQuery } = postRes.data;
      
      if (!temp_token) {
        console.error('No temporary token received from server');
        setError('Failed to initialize streaming connection');
        setLoading(false);
        return;
      }
      
      // Check cancellation before setting up the SSE.
      if (cancelRequestedRef.current) {
        console.log('Cancellation requested; aborting streaming initiation.');
        setLoading(false);
        return;
      }
      
      // Reset skipFirstPage for future searches.
      setSkipFirstPage(undefined);
      
      const finalQuery = updatedQuery || requestData.query;
      console.log('Setting tech response with query:', finalQuery);
      setTechResponse((prev: any) => ({
        ...prev,
        query: finalQuery,
      }));
      
      console.log(`Received temporary token: ${temp_token.substring(0, 10)}...`);
      
      // Construct the streaming URL with the token and additional parameters
      const streamURL = `${techURL}?temp_token=${encodeURIComponent(temp_token)}&query=${encodeURIComponent(finalQuery)}&thread_id=${requestData.thread_id || ''}&project_id=${requestData.projectId || ''}`;
      console.log(`Connecting to streaming endpoint: ${streamURL}`);
      
      // Initialize EventSource for SSE
      const es = new EventSource(streamURL);

      // Check again: if cancellation was requested in the meantime, close the connection.
      if (cancelRequestedRef.current) {
        console.log('Cancellation requested after EventSource creation; closing connection.');
        es.close();
        setLoading(false);
        return;
      }

      eventSourceRef.current = es;

      // Set up a timeout to detect stalled connections
      const connectionTimeoutId = setTimeout(() => {
        if (es.readyState !== EventSource.CLOSED) {
          console.warn('⚠️ Connection timeout: No data received within 30 seconds');
          console.log('Current EventSource readyState:', es.readyState);
          console.log('EventSource readyState values: CONNECTING=0, OPEN=1, CLOSED=2');
          
          // If the connection is still in CONNECTING state after 30 seconds, it's likely stalled
          if (es.readyState === EventSource.CONNECTING) {
            console.error('Connection appears to be stalled in CONNECTING state');
            es.close();
            eventSourceRef.current = null;
            setLoading(false);
            setError('Connection timeout. Please try again.');
          }
        }
      }, 30000); // 30 second timeout

      es.onopen = () => {
        console.log('✅ Connection to TechScouting SSE opened successfully.');
        console.log('EventSource readyState after open:', es.readyState);
      };

      es.onmessage = (evt: MessageEvent) => {
        try {
          console.log('📩 Raw SSE message received:', evt.data);
          const newMsg = JSON.parse(evt.data);
          console.log('Parsed SSE message:', newMsg);
          if (newMsg.results) {
            console.log(`Processing ${newMsg.results.length} results from source: ${newMsg.source || 'unknown'}`);
            handleSSEChunk(newMsg);
          } else {
            console.warn('Received message without results:', newMsg);
          }
        } catch (error) {
          console.error('Error parsing or handling SSE message:', error, 'Raw data:', evt.data);
        }
      };

      es.onerror = (evt: Event) => {
        console.error('❌ TechScouting SSE error:', evt);
        console.log('EventSource readyState on error:', es.readyState);
        console.log('EventSource readyState values: CONNECTING=0, OPEN=1, CLOSED=2');
        
        // Clear the connection timeout
        clearTimeout(connectionTimeoutId);
        
        // If we're in CONNECTING state and had an error, the connection might be failing
        if (es.readyState === EventSource.CONNECTING) {
          console.warn('Connection in CONNECTING state after error - might be failing to connect');
        }
        
        es.close();
        eventSourceRef.current = null;
        setLoading(false);
      };

      es.addEventListener('end', (evt: Event) => {
        try {
          console.log('🏁 TechScouting SSE ended event received:', evt);
          console.log('EventSource readyState on end event:', es.readyState);
          
          // Clear the connection timeout
          clearTimeout(connectionTimeoutId);
          
          // Process any final data if needed
          if ('data' in evt && (evt as any).data) {
            const endData = JSON.parse((evt as any).data);
            console.log('End event data:', endData);
            if (endData && endData.status === 'complete') {
              console.log('Stream completed successfully with message:', endData.message);
            }
          }
        } catch (error) {
          console.error('Error processing end event:', error);
        } finally {
          console.log('Closing EventSource connection');
          es.close();
          eventSourceRef.current = null;
          setLoading(false);
        }
      });
      
      es.addEventListener('error', (evt: Event) => {
        console.error('❌ TechScouting SSE error event received:', evt);
        try {
          // Check if this is a MessageEvent with data
          if ('data' in evt) {
            const errorData = JSON.parse((evt as any).data);
            console.error('Error data:', errorData);
            setError(`Error: ${errorData.error || 'Unknown error'}`);
          } else {
            console.error('Error event does not contain data property');
            setError('An error occurred with the connection');
          }
        } catch (error) {
          console.error('Error parsing error event data:', error);
          setError('An unknown error occurred');
        }
        es.close();
        eventSourceRef.current = null;
        setLoading(false);
      });
      
      // Add listener for info events (like 'no data found')
      es.addEventListener('info', (evt: Event) => {
        console.log('ℹ️ TechScouting SSE info event received:', evt);
        try {
          // Check if this is a MessageEvent with data
          if ('data' in evt) {
            const infoData = JSON.parse((evt as any).data);
            console.log('Info data:', infoData);
            
            if (infoData.status === 'no_data') {
              console.log('No data found for query');
              // Show a user-friendly message
              setError(infoData.message || 'No results found for your query');
            }
          }
        } catch (error) {
          console.error('Error parsing info event data:', error);
        }
      });
    } catch (error: any) {
      console.error('❌ Error in initiateStreaming:', error);
      console.error('Error details:', error.response?.data || error.message);
      if (!retryAttempt && !retry) {
        setRetryAttempt(true);
        console.warn('⚠️ Retrying request after error...');
        await initiateStreaming(requestData, true);
      } else {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.error) {
          setError(error.response.data.error);
        } else if (error.response && error.response.status === 401) {
          setError('Unauthorized. Please log in.');
        } else {
          setError('An error occurred while initiating the request.');
        }
      }
    }
  };

  // SSE chunk handler: merge new results into context.
  const handleSSEChunk = (chunk: any) => {
    console.log('🔄 Processing SSE chunk:', chunk);
    console.log('Chunk source:', chunk.source || 'not specified');
    console.log('Chunk type:', chunk.type || 'not specified');
    console.log('Results count:', chunk.results?.length || 0);
    
    // We only need to sanitize text fields now, source is already set correctly
    const resultsWithCleanText = chunk.results.map((result: any) => ({
      ...result,
      company: result.company?.replace(/[|]/g, '') ?? '',
      abstract: result.abstract?.replace(/[|]/g, '') ?? ''
    }));
    
    setTechResponse((prev: any) => {
      const prevResultsCount = prev?.results?.length || 0;
      const newResults = prev?.results
        ? [...prev.results, ...resultsWithCleanText]
        : resultsWithCleanText;
      console.log(`Updating techResponse: ${prevResultsCount} previous results + ${resultsWithCleanText.length} new results = ${newResults.length} total`);
      return {
        ...prev,
        ...chunk,
        query: prev?.query || query,
        results: newResults,
        filtered_result_count: newResults.length,
      };
    });
  };

  // Utility: sanitize the backend response.
  const sanitizeResponse = (data: any) => {
    console.log('Sanitizing response data:', data);
    return {
      ...data,
      results: sanitizeResults(data.results),
    };
  };

  const sanitizeResults = (arr: any[]) => {
    if (!arr) {
      console.log('No results to sanitize');
      return [];
    }
    console.log(`Sanitizing ${arr.length} results`);
    
    // We only need to sanitize text fields now, source is already set correctly
    return arr.map((item: any) => ({
      ...item,
      company: item.company?.replace(/[|]/g, '') ?? '',
      abstract: item.abstract?.replace(/[|]/g, '') ?? ''
    }));
  };

  // Handle report generation.
  const handleGenerateReport = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    await handleSubmit(undefined, true);
  };

  // Handle threshold changes.
  const handleThresholdChange = (val: string) => {
    switch (val) {
      case 'Specific':
        setThreshold(0.44);
        setSelectedPrecision('Specific');
        break;
      case 'General':
        setThreshold(0.39);
        setSelectedPrecision('General');
        break;
      case 'Broad':
        setThreshold(0.32);
        setSelectedPrecision('Broad');
        break;
      default:
        setThreshold(0.39);
        setSelectedPrecision('Precision');
    }
    setPrecisionDropdownOpen(false);
    libraryDropdownButtonRef.current?.focus();
  };

  // Handle data source changes.
  const handleDataSourceChange = (val: string) => {
    switch (val) {
      case 'Startup & SBIR':
        setUseSBIR(true);
        setUseStartup(true);
        setSelectedDataSource('Startup & SBIR');
        break;
      case 'Startup':
        setUseSBIR(false);
        setUseStartup(true);
        setSelectedDataSource('Startup');
        break;
      case 'SBIR':
        setUseSBIR(true);
        setUseStartup(false);
        setSelectedDataSource('SBIR');
        break;
      default:
        setUseSBIR(true);
        setUseStartup(true);
        setSelectedDataSource('Startup & SBIR');
    }
    setDataSourceDropdownOpen(false);
    libraryDropdownButtonRef.current?.focus();
  };

  // Dropdown open/close handlers.
  const handleDropdownButtonClick = () => {
    setPrecisionDropdownOpen(!precisionDropdownOpen);
    setLibraryDropdownOpen(false);
    setDataSourceDropdownOpen(false);
  };

  const handleLibraryDropdownClick = () => {
    setLibraryDropdownOpen(!libraryDropdownOpen);
    setPrecisionDropdownOpen(false);
    setDataSourceDropdownOpen(false);
  };

  const handleDataSourceDropdownClick = () => {
    setDataSourceDropdownOpen(!dataSourceDropdownOpen);
    setPrecisionDropdownOpen(false);
    setLibraryDropdownOpen(false);
  };

  // When user selects a library document.
  const handleLibrarySelection = (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    doc: Document | null
  ) => {
    e.preventDefault();
    if ('stopPropagation' in e) e.stopPropagation();

    if (doc) {
      setSelectedDocumentId(doc.id);
      setSelectedDocumentTitle(doc.documentTitle);
    } else {
      setSelectedDocumentId(null);
      setSelectedDocumentTitle('Library');
    }
    setLibraryDropdownOpen(false);
    setTimeout(() => {
      submitButtonRef.current?.focus();
    }, 0);
  };

  // Download handler.
  const handleDownload = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = 'TechScouting_Report.docx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Close dropdowns if clicking outside.
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        precisionDropdownOpen &&
        precisionMenuRef.current &&
        !precisionMenuRef.current.contains(event.target as Node) &&
        !precisionDropdownButtonRef.current?.contains(event.target as Node)
      ) {
        setPrecisionDropdownOpen(false);
      }
      if (
        libraryDropdownOpen &&
        libraryDropdownMenuRef.current &&
        !libraryDropdownMenuRef.current.contains(event.target as Node) &&
        !libraryDropdownButtonRef.current?.contains(event.target as Node)
      ) {
        setLibraryDropdownOpen(false);
      }
      if (
        dataSourceDropdownOpen &&
        dataSourceMenuRef.current &&
        !dataSourceMenuRef.current.contains(event.target as Node) &&
        !dataSourceDropdownButtonRef.current?.contains(event.target as Node)
      ) {
        setDataSourceDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [precisionDropdownOpen, libraryDropdownOpen, dataSourceDropdownOpen]);

  // Focus error messages.
  useEffect(() => {
    if (error && errorRef.current) {
      errorRef.current.focus();
    }
  }, [error]);

  // Keyboard navigation in dropdowns.
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Precision dropdown
      if (precisionDropdownOpen && precisionMenuRef.current) {
        const items = precisionMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex(item => item === document.activeElement);
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            (items[(activeIndex + 1) % items.length] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            (items[(activeIndex - 1 + items.length) % items.length] as HTMLElement).focus();
            break;
          case 'Enter':
            event.preventDefault();
            if (activeIndex !== -1) {
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Escape':
            setPrecisionDropdownOpen(false);
            precisionDropdownButtonRef.current?.focus();
            break;
          case 'Tab':
            if (!event.shiftKey && activeIndex === items.length - 1) {
              event.preventDefault();
              setPrecisionDropdownOpen(false);
              libraryDropdownButtonRef.current?.focus();
            } else if (event.shiftKey && activeIndex === 0) {
              event.preventDefault();
              setPrecisionDropdownOpen(false);
              precisionDropdownButtonRef.current?.focus();
            }
            break;
        }
      }
      // Library dropdown
      if (libraryDropdownOpen && libraryDropdownMenuRef.current) {
        const items = libraryDropdownMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex(item => item === document.activeElement);
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            (items[(activeIndex + 1) % items.length] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            (items[(activeIndex - 1 + items.length) % items.length] as HTMLElement).focus();
            break;
          case 'Enter':
            event.preventDefault();
            if (activeIndex !== -1) {
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Escape':
            setLibraryDropdownOpen(false);
            libraryDropdownButtonRef.current?.focus();
            break;
          case 'Tab':
            if (!event.shiftKey && activeIndex === items.length - 1) {
              event.preventDefault();
              setLibraryDropdownOpen(false);
              submitButtonRef.current?.focus();
            } else if (event.shiftKey && activeIndex === 0) {
              event.preventDefault();
              setLibraryDropdownOpen(false);
              libraryDropdownButtonRef.current?.focus();
            }
            break;
        }
      }
      // Data source dropdown
      if (dataSourceDropdownOpen && dataSourceMenuRef.current) {
        const items = dataSourceMenuRef.current.querySelectorAll('div[role="menuitem"]');
        const activeIndex = Array.from(items).findIndex(item => item === document.activeElement);
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            (items[(activeIndex + 1) % items.length] as HTMLElement).focus();
            break;
          case 'ArrowUp':
            event.preventDefault();
            (items[(activeIndex - 1 + items.length) % items.length] as HTMLElement).focus();
            break;
          case 'Enter':
            event.preventDefault();
            if (activeIndex !== -1) {
              (items[activeIndex] as HTMLElement).click();
            }
            break;
          case 'Escape':
            setDataSourceDropdownOpen(false);
            dataSourceDropdownButtonRef.current?.focus();
            break;
          case 'Tab':
            if (!event.shiftKey && activeIndex === items.length - 1) {
              event.preventDefault();
              setDataSourceDropdownOpen(false);
              libraryDropdownButtonRef.current?.focus();
            } else if (event.shiftKey && activeIndex === 0) {
              event.preventDefault();
              setDataSourceDropdownOpen(false);
              dataSourceDropdownButtonRef.current?.focus();
            }
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [precisionDropdownOpen, libraryDropdownOpen, dataSourceDropdownOpen]);

  // When a dropdown opens, focus the first item.
  useEffect(() => {
    if (precisionDropdownOpen && precisionMenuRef.current) {
      const firstItem = precisionMenuRef.current.querySelector('div[role="menuitem"]');
      if (firstItem) (firstItem as HTMLElement).focus();
    }
    if (libraryDropdownOpen && libraryDropdownMenuRef.current) {
      const firstItem = libraryDropdownMenuRef.current.querySelector('div[role="menuitem"]');
      if (firstItem) (firstItem as HTMLElement).focus();
    }
    if (dataSourceDropdownOpen && dataSourceMenuRef.current) {
      const firstItem = dataSourceMenuRef.current.querySelector('div[role="menuitem"]');
      if (firstItem) (firstItem as HTMLElement).focus();
    }
  }, [precisionDropdownOpen, libraryDropdownOpen, dataSourceDropdownOpen]);

  // Function to save a company to the project
  const handleSaveCompany = async (company: any) => {
    try {
      const companyData = {
        name: company.company,
        abstract: company.abstract,
        harmonic_company_id:company.harmonic_company_id,
        sbir_company_id: company.sbir_company_id,
        source: company.source
      };

      await axiosRequest(
        `${process.env.REACT_APP_PROJECTS_API_URL}${selectedProjectId}/techcompanies/save/`,
        'post',
        companyData
      );

      // Update local state to show the company is saved
      setSavedCompanies(prev => new Set(prev).add(company.company));
    } catch (error) {
      console.error('Error saving company:', error);
      setError('Failed to save company. Please try again.');
    }
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (company: any) => {
    handleSaveCompany(company);
  };

  // Cleanup: close the EventSource when component unmounts.
  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
    };
  }, []);

  // Render the component.
  return (
    <div className={`tech-scouting-page ${isDarkMode ? 'dark' : 'light'}`}>
      <Helmet>
        <title>Tech Scouting | Innoscale AI</title>
      </Helmet>

      {/* Header */}
      <div className="tech-scouting-header">
        <div className="tech-scouting-title-row">
          <div className="tech-scouting-title-section">
            <h1 className="tech-scouting-title">
              <div className="tech-scouting-prompt-container">
                <div 
                  className={`tech-scouting-query-input ${isDarkMode ? 'dark' : 'light'}`}
                  onClick={() => setIsPromptModalOpen(true)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setIsPromptModalOpen(true);
                    }
                  }}
                  aria-label="Edit search prompt"
                >
                  {query || "Click to enter search prompt..."}
                </div>
                {isPromptModalOpen && (
                  <PromptInputModal
                    isOpen={isPromptModalOpen}
                    onClose={() => setIsPromptModalOpen(false)}
                    onSubmit={(newPrompt) => {
                      console.log('PromptInputModal submitted with new prompt:', newPrompt);
                      
                      // If currently loading, cancel the existing request first
                      if (loading) {
                        console.log('Cancelling current request before submitting new one');
                        cancelRequestedRef.current = true;
                        if (eventSourceRef.current) {
                          console.log('Closing existing SSE connection before submitting new request');
                          eventSourceRef.current.close();
                          eventSourceRef.current = null;
                        }
                        setSkipFirstPage(undefined);
                        // Don't set loading to false here, as we're about to start a new request
                      }
                      
                      // Set the query state
                      setQuery(newPrompt);
                      console.log('Query set to:', newPrompt);
                      setIsPromptModalOpen(false);
                      
                      // Call handleSubmit with the new prompt directly
                      handleSubmit(false, false, newPrompt);
                    }}
                    initialPrompt={query}
                    isDarkMode={isDarkMode}
                    isTechScouting={true}
                  />
                )}
                <button
                  onClick={() => {
                    if (loading) {
                      cancelRequestedRef.current = true;
                      if (eventSourceRef.current) {
                        console.log('Cancel clicked, closing SSE.');
                        eventSourceRef.current.close();
                        eventSourceRef.current = null;
                      }
                      setSkipFirstPage(undefined);
                      setLoading(false);
                    } else {
                      handleSubmit();
                    }
                  }}
                  className={`tech-scouting-submit-button ${isDarkMode ? 'dark' : 'light'} ${loading ? 'loading' : ''}`}
                  aria-label={loading ? 'Stop' : 'Submit'}
                  ref={submitButtonRef}
                >
                  {loading ? '✕' : '➤'}
                </button>
              </div>
            </h1>
          </div>
          {techResponse?.results && techResponse.results.length > 0 && (
            <div className="tech-scouting-results-header">
              <div className="tech-scouting-search-container">
                <div className="tech-scouting-search-wrapper">
                  <input
                    type="text"
                    placeholder={`Search ${searchMode === 'company' ? 'companies' : 'descriptions'}...`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={`tech-scouting-search-input ${isDarkMode ? 'dark' : 'light'}`}
                    aria-label={`Search ${searchMode === 'company' ? 'companies' : 'descriptions'}`}
                  />
                  <div className="tech-scouting-search-controls">
                    {searchTerm && (
                      <button 
                        className="tech-scouting-search-clear"
                        onClick={() => setSearchTerm('')}
                        aria-label="Clear search"
                      >
                        ✕
                      </button>
                    )}
                    <div className="tech-scouting-search-toggle-container">
                      <button
                        className={`tech-scouting-search-toggle-button ${searchMode === 'company' ? 'active' : ''}`}
                        onClick={() => setSearchMode('company')}
                        aria-pressed={searchMode === 'company'}
                      >
                        Company
                      </button>
                      <span className="tech-scouting-search-toggle-separator">/</span>
                      <button
                        className={`tech-scouting-search-toggle-button ${searchMode === 'description' ? 'active' : ''}`}
                        onClick={() => setSearchMode('description')}
                        aria-pressed={searchMode === 'description'}
                      >
                        Desc
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <h2 className={`tech-scouting-results-count tech-scouting-results-count-position ${isDarkMode ? 'dark' : 'light'}`}>
                {techResponse.results.filter((result: any) => 
                  searchMode === 'company' 
                    ? result.company.toLowerCase().includes(searchTerm.toLowerCase())
                    : result.abstract.toLowerCase().includes(searchTerm.toLowerCase())
                ).length} {techResponse.results.filter((result: any) => 
                  searchMode === 'company' 
                    ? result.company.toLowerCase().includes(searchTerm.toLowerCase())
                    : result.abstract.toLowerCase().includes(searchTerm.toLowerCase())
                ).length === 1 ? 'Result' : 'Results'}
              </h2>
            </div>
          )}
        </div>

        <div className="tech-scouting-buttons-row">
          <div className="tech-scouting-controls">
            {/* Precision Dropdown */}
            <div className="tech-scouting-button-with-tooltip">
              <button
                type="button"
                className={`tech-scouting-dropdown-button ${isDarkMode ? 'dark' : 'light'}`}
                onClick={handleDropdownButtonClick}
                aria-expanded={precisionDropdownOpen}
                aria-controls="tech-scouting-precision-dropdown-menu"
                ref={precisionDropdownButtonRef}
                disabled={loading}
              >
                &#9776; {selectedPrecision}
              </button>
              <span className="tech-scouting-tooltip-text">
                Limits number of results based on relevance
              </span>
              <div
                className={`tech-scouting-dropdown-menu ${isDarkMode ? 'dark' : 'light'} ${precisionDropdownOpen ? 'open' : ''}`}
                id="tech-scouting-precision-dropdown-menu"
                role="menu"
                ref={precisionMenuRef}
                aria-hidden={!precisionDropdownOpen}
              >
                <div
                  tabIndex={0}
                  role="menuitem"
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onClick={() => handleThresholdChange('Specific')}
                  onKeyDown={(event) => { if (event.key === 'Enter') handleThresholdChange('Specific'); }}
                >
                  Specific
                </div>
                <div
                  tabIndex={0}
                  role="menuitem"
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onClick={() => handleThresholdChange('General')}
                  onKeyDown={(event) => { if (event.key === 'Enter') handleThresholdChange('General'); }}
                >
                  General
                </div>
                <div
                  tabIndex={0}
                  role="menuitem"
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onClick={() => handleThresholdChange('Broad')}
                  onKeyDown={(event) => { if (event.key === 'Enter') handleThresholdChange('Broad'); }}
                >
                  Broad
                </div>
              </div>
            </div>

            {/* Library Dropdown */}
            <div className="tech-scouting-button-with-tooltip">
              <button
                type="button"
                className={`tech-scouting-library-dropdown-button ${isDarkMode ? 'dark' : 'light'}`}
                onClick={handleLibraryDropdownClick}
                aria-expanded={libraryDropdownOpen}
                aria-controls="tech-scouting-library-dropdown-menu"
                ref={libraryDropdownButtonRef}
                disabled={loading}
              >
                &#128194; {selectedDocumentTitle}
              </button>
              <span className="tech-scouting-tooltip-text">
                Select a document from your library
              </span>
              <div
                className={`tech-scouting-library-dropdown-menu ${isDarkMode ? 'dark' : 'light'} ${libraryDropdownOpen ? 'open' : ''}`}
                id="tech-scouting-library-dropdown-menu"
                role="menu"
                ref={libraryDropdownMenuRef}
                aria-hidden={!libraryDropdownOpen}
              >
                <div
                  role="menuitem"
                  tabIndex={0}
                  onClick={(evt) => handleLibrarySelection(evt, null)}
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onKeyDown={(event) => { if (event.key === 'Enter') handleLibrarySelection(event, null); }}
                >
                  -- No Document --
                </div>
                {documents.map((doc) => (
                  <div
                    key={doc.id}
                    role="menuitem"
                    tabIndex={0}
                    onClick={(evt) => handleLibrarySelection(evt, doc)}
                    className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                    onKeyDown={(event) => { if (event.key === 'Enter') handleLibrarySelection(event, doc); }}
                  >
                    {doc.documentTitle}
                  </div>
                ))}
              </div>
            </div>

            {/* Data Source Dropdown */}
            <div className="tech-scouting-button-with-tooltip">
              <button
                type="button"
                className={`tech-scouting-dropdown-button ${isDarkMode ? 'dark' : 'light'}`}
                onClick={handleDataSourceDropdownClick}
                aria-expanded={dataSourceDropdownOpen}
                aria-controls="tech-scouting-data-source-dropdown-menu"
                ref={dataSourceDropdownButtonRef}
                disabled={loading}
              >
                <span className="globe-icon">&#127760;</span> {selectedDataSource}
              </button>
              <span className="tech-scouting-tooltip-text">
                Select a data source
              </span>
              <div
                className={`tech-scouting-dropdown-menu ${isDarkMode ? 'dark' : 'light'} ${dataSourceDropdownOpen ? 'open' : ''}`}
                id="tech-scouting-data-source-dropdown-menu"
                role="menu"
                ref={dataSourceMenuRef}
                aria-hidden={!dataSourceDropdownOpen}
              >
                <div
                  tabIndex={0}
                  role="menuitem"
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onClick={() => handleDataSourceChange('Startup & SBIR')}
                  onKeyDown={(event) => { if (event.key === 'Enter') handleDataSourceChange('Startup & SBIR'); }}
                >
                  Startup & SBIR
                </div>
                <div
                  tabIndex={0}
                  role="menuitem"
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onClick={() => handleDataSourceChange('Startup')}
                  onKeyDown={(event) => { if (event.key === 'Enter') handleDataSourceChange('Startup'); }}
                >
                  Startup
                </div>
                <div
                  tabIndex={0}
                  role="menuitem"
                  className={`tech-scouting-dropdown-item ${isDarkMode ? 'dark' : 'light'}`}
                  onClick={() => handleDataSourceChange('SBIR')}
                  onKeyDown={(event) => { if (event.key === 'Enter') handleDataSourceChange('SBIR'); }}
                >
                  SBIR
                </div>
              </div>
            </div>

          </div>

          {/* Generate Report Button */}
          {techResponse?.results && techResponse.results.length > 0 && (
            <button
              onClick={handleGenerateReport}
              className={`tech-scouting-generate-report ${isDarkMode ? 'dark' : 'light'}`}
              disabled={loading}
            >
              Generate Report
            </button>
          )}
        </div>
      </div>
      <div className="tech-scouting-underline" />

      {/* Main Content */}
      <div className="tech-scouting-main-content">
        {loading && <Loading />}
        {error && <div className="error-message">{error}</div>}

        {techResponse?.results && techResponse.results.length > 0 && (
          <table className="tech-scouting-table">
            <thead>
              <tr>
                <th>Company</th>
                <th>Description</th>
                <th>Source</th>
                <th>Save</th>
              </tr>
            </thead>
            <tbody>
              {techResponse.results
                .filter((result: any) => 
                  searchMode === 'company' 
                    ? result.company.toLowerCase().includes(searchTerm.toLowerCase())
                    : result.abstract.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((result: any, index: number) => (
                <tr key={index}>
                  <td>
                    <div className="company-name-cell">
                      <button
                        onClick={() => {
                          console.log('Selected company:', result);
                          // Store the company data in context
                          setSelectedCompany({
                            company: result.company,
                            abstract: result.abstract,
                            source: result.source,
                            id: result.id,
                            harmonic_company_id: result.source === 'Startup' ? result.harmonic_company_id : result.harmonic_company_id,
                            sbir_company_id: result.source === 'SBIR' ? result.sbir_company_id : result.harmonic_company_id
                          });
                          setSelectedCompanyOrigin('TechScouting');
                          navigate('/company-details');
                        }}
                        className={`company-button ${isDarkMode ? 'dark' : 'light'}`}
                      >
                        {result.company}
                      </button>
                    </div>
                  </td>
                  <td>
                    <div className="company-description-cell">
                      {result.abstract}
                    </div>
                  </td>
                  <td>
                    <div 
                      className="source-cell"
                      data-source={result.source || 'unknown'}
                    >
                      {result.source}
                    </div>
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      className="tech-scouting-result-checkbox"
                      checked={savedCompanies.has(result.company)}
                      onChange={() => handleCheckboxChange(result)}
                      aria-label={`Save ${result.company}`}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default TechScouting;
