// StakeholderDetails.tsx

import React, { useContext } from 'react';
import { StakeholderIdentificationContext } from '../ContextStore';
import './style/StakeholderDetails.css';
import { useNavigate } from 'react-router-dom';

interface StakeholderDetailsProps {
  isDarkMode: boolean;
}

const StakeholderDetails: React.FC<StakeholderDetailsProps> = ({ isDarkMode }) => {
  const navigate = useNavigate();
  const stakeholderContext = useContext(StakeholderIdentificationContext);
  if (!stakeholderContext) {
    throw new Error('StakeholderIdentificationContext not found');
  }
  const { selectedStakeholder, setSelectedStakeholder, selectedStakeholderOrigin } = stakeholderContext;

  if (!selectedStakeholder) {
    return (
      <div className={`stakeholder-details-container ${isDarkMode ? 'dark' : 'light'}`}>
        <h1>Stakeholder Details Not Available</h1>
        <button
          onClick={() => navigate('/techscouting')}
          className={`stakeholder-details-back-button ${isDarkMode ? 'dark' : 'light'}`}
        >
          Go Back
        </button>
      </div>
    );
  }

  // Destructure the selectedStakeholder for easier access
  const {
    name,
    email,
    organization,
    title,
    source
  } = selectedStakeholder;

  // Helper function to format info
  const formatInfo = (info: string | null | undefined): string => {
    if (!info) return 'No Data';
    return info.trim() !== '' ? info : 'No Data';
  };

  // Handle Go Back button click
  const handleGoBack = () => {
    setSelectedStakeholder(null); // Clear the selected stakeholder
    if (selectedStakeholderOrigin === 'ProjectDashboard') {
      navigate('/dashboard');
    } else {
      // Default to TechScouting
      navigate('/techscouting');
    }
  };

  return (
    <div className={`stakeholder-details-container ${isDarkMode ? 'dark' : 'light'}`}>
      <nav aria-label="Primary Navigation" className="primary-nav"></nav>
      <main role="main" tabIndex={-1} className="main-content">
        <h1>{formatInfo(organization)}</h1>
        <p>
          <strong>Title:</strong> {formatInfo(title)}
        </p>
        <p>
          <strong>Name:</strong> {formatInfo(name)}
        </p>
        <p>
          <strong>Email:</strong>{' '}
          {email ? (
            <a href={`mailto:${email}`}>
              {email}
            </a>
          ) : (
            'No Data'
          )}
        </p>
        <p>
          <strong>Source:</strong> {formatInfo(source)}
        </p>
        <button
          onClick={handleGoBack}
          className={`stakeholder-details-back-button ${isDarkMode ? 'dark' : 'light'}`}
        >
          Go Back
        </button>
      </main>
    </div>
  );
};

export default StakeholderDetails;
